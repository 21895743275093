import { Box, Button, Typography } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { injected, walletconnect } from 'src/connectors';
import { SUPPORTED_WALLETS } from 'src/constants/wallet';
import { shortenAddress } from 'src/utils';
import MetamaskIcon from 'src/assets/img/metamask.png';
import WalletConnectIcon from 'src/assets/img/walletConnectIcon.svg';

interface AccountDetailsProps {
  openOptions: () => void;
}

export const AccountDetails = ({ openOptions }: AccountDetailsProps) => {
  const { account, connector } = useWeb3React();

  const formatConnectorName = () => {
    const { ethereum } = window;
    const isMetaMask = !!(ethereum && ethereum.isMetaMask);
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        (k) =>
          SUPPORTED_WALLETS[k].connector === connector &&
          (connector !== injected || isMetaMask === (k === 'METAMASK'))
      )
      .map((k) => SUPPORTED_WALLETS[k].name)[0];

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: 'initial',
          fontSize: '0.825rem',
          fontWeight: 500,
        }}
      >
        Connected with {name}
      </Box>
    );
  };

  const getStatusIcon = () => {
    if (connector === injected) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            mr: 1,
            height: 16,
            width: 16,
            alignItems: { xs: 'flex-end', md: 'center' },
            img: {
              boxShadow: 'none',
            },
          }}
        >
          <img
            height={16}
            width={16}
            src={MetamaskIcon}
            alt={'WalletConnect logo'}
          />
        </Box>
      );
    } else if (connector === walletconnect) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            mr: 1,
            height: 16,
            width: 16,
            alignItems: { xs: 'flex-end', md: 'center' },
            img: {
              boxShadow: 'none',
            },
          }}
        >
          <img
            height={16}
            width={16}
            src={WalletConnectIcon}
            alt={'WalletConnect logo'}
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <Box>
      <Box
        sx={{
          padding: '1rem',
          border: `1px solid`,
          borderRadius: 1,
          position: 'relative',
          display: 'grid',
          gridRowGap: '12px',
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 400,
          }}
        >
          {formatConnectorName()}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {connector !== injected && (
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: 'fit-content',
                  ml: 1,
                  padding: '4px 6px',
                  mr: 1,
                  fontSize: '.825rem',
                  fontWeight: 400,
                }}
                onClick={() => {
                  (connector as any).close();
                }}
              >
                Disconnect
              </Button>
            )}
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                width: 'fit-content',
                ml: 1,
                padding: '4px 6px',
                fontSize: '.825rem',
                fontWeight: 400,
              }}
              onClick={() => {
                openOptions();
              }}
            >
              Change
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 400,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              minWidth: 0,
              width: '100%',
              fontWeight: 500,
              fontSize: '1.25rem',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {getStatusIcon()}
              <Typography
                variant="h6"
                sx={{
                  minWidth: 0,
                  margin: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {account && shortenAddress(account)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
