import { Button, Grid, Typography } from '@mui/material';
import React from 'react';

export const About = () => {
  return (
    <Grid container sx={{ justifyContent: 'center', mb: 4, mt: 2 }}>
      <Grid item xs={12} md={8} xl={5} sx={{ textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          NFT Pixel Canvas
        </Typography>
        <Typography variant="subtitle1">
          The NFT Pixel Canvas project is a 1280 x 720 pixels canvas that is
          split into 9216 unique NFTs representing 10 x 10 Pixel Boxes.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          NFT Pixel Canvas enables owners of the token to control part of the
          public canvas and invest/store the value inside the canvas.
        </Typography>
        <Button
          href="https://opensea.io/collection/nft-pixel-canvas"
          target="_blank"
          variant="contained"
          color="secondary"
          disableRipple
        >
          Browse on Opensea
        </Button>
      </Grid>
    </Grid>
  );
};
