import Countdown, { CountdownRendererFn } from 'react-countdown';
import { Link } from 'react-router-dom';
import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { RESERVATION_MINT_END_TIME } from 'src/constants/misc';
import { ROUTE_TO_RESERVED_MINT_PAGE } from 'src/constants/routes';
import { CountdownBox } from './CountdownBox';
import { DrawBillboard } from 'src/components/DrawBillboard';

export const ReservedMint = () => {
  const renderer: CountdownRendererFn = ({
    completed,
    formatted: { days, hours, minutes, seconds },
  }) => {
    if (completed) {
      // Render a completed state
      return <DrawBillboard />;
    } else {
      // Render a countdown
      return (
        <>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              lg={6}
              sx={{ textAlign: 'center' }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ mb: 1 }}
                gutterBottom
              >
                Reserved Pixels mint phase ends in:
              </Typography>
              <CountdownBox
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ justifyContent: 'center', mt: 3 }}>
            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              lg={6}
              sx={{ textAlign: 'right' }}
            >
              <Button
                component={Link}
                to={ROUTE_TO_RESERVED_MINT_PAGE}
                variant="contained"
                color="primary"
              >
                Mint reserved pixels
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <Countdown date={RESERVATION_MINT_END_TIME} renderer={renderer} />
    </>
  );
};
