import crypto from 'crypto';

const sha256 = (inputString: string) => {
  return crypto.createHash('sha256').update(inputString).digest('hex');
};

const hmacSha256 = (inputKey: string, inputString: string) => {
  return crypto
    .createHmac('sha256', inputKey)
    .update(inputString)
    .digest('hex');
};

const usingInitialStateExpandData = (startState = '') => {
  const state: string[] = [];
  for (let i = 0; i < 10; i++) {
    const stateToString = state.join('');
    const toHash = stateToString === '' ? startState : stateToString;
    state.push(sha256(`${toHash}`));
  }
  // 20 bytes too much on 10th iteration, we just need 300 bytes for the 10x10px
  return state.join('');
};

export const generateInitialPixelData = (pixelId: number | string) => {
  const pixelBoxSeed = hmacSha256(
    process.env.REACT_APP_SEED || '',
    `${pixelId}`
  );

  const pregeneratedData = usingInitialStateExpandData(pixelBoxSeed);

  const data = Buffer.from(pregeneratedData, 'hex').slice(0, 300);
  const initialData = toHexString(data).match(regexSplitPixels);
  return initialData ? flipBytesForArray(initialData) : [];
};

export const regexSplitPixels = new RegExp(`.{1,6}`, 'g');
export const regexSplitPixelsBytes = new RegExp(`.{1,2}`, 'g');
export const hexColorRegex = /^#[0-9A-F]{6}$/i;

export const flipBytes = (hexString: string) => {
  const bytes = hexString.match(regexSplitPixelsBytes);
  if (bytes) {
    return `${bytes[2]}${bytes[1]}${bytes[0]}`;
  }
  return hexString;
};

export const flipBytesForArray = (tokenData: string[]) => {
  return tokenData.map((el) => flipBytes(el));
};

export const toHexString = (byteArray: Uint8Array) => {
  return Array.prototype.map
    .call(byteArray, (byte) => {
      return ('0' + (byte & 0xff).toString(16)).slice(-2);
    })
    .join('');
};

export const readFileAsArrayBuffer = (file: File) => {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e?.target?.result) {
        resolve(e.target.result);
      }
    };
    reader.onerror = (e) => {
      const result = e?.target?.result || '';
      reject(new Error(`Error reading' ${file.name} ${result}`));
    };
    reader.readAsArrayBuffer(file);
  });
};

export const arrayBufferToHex = (
  arrayBuffer: ArrayBuffer | string,
  flip = true
) => {
  if (arrayBuffer && arrayBuffer instanceof ArrayBuffer) {
    const array = new Uint8Array(arrayBuffer as ArrayBuffer);

    const imageDataWithoutHeader = array.slice(54);

    const rows = [];
    for (let i = 0; i < 10; i++) {
      const start = i * 30 + i * 2;
      const end = start + 30;
      if (flip) {
        rows.unshift(toHexString(imageDataWithoutHeader.slice(start, end)));
      } else {
        rows.push(toHexString(imageDataWithoutHeader.slice(start, end)));
      }
    }
    const bytes = rows.join('').match(regexSplitPixels);
    if (bytes) {
      return [...flipBytesForArray(bytes)];
    }
  }
  return null;
};
