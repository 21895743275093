import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import {
  ROUTE_TO_ABOUT_PAGE,
  ROUTE_TO_WARNING_PAGE,
  ROUTE_TO_COLLECTION_PAGE,
  ROUTE_TO_HOME_PAGE,
} from 'src/constants/routes';
import { Web3Connect } from '../Web3Status';
import { Sidebar } from './Siderbar';

const drawerWidth = 240;

export const Header = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { account } = useWeb3React();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: '#15232d',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            <Link
              component={RouteLink}
              to={ROUTE_TO_HOME_PAGE}
              sx={{ textDecoration: 'none', color: 'white' }}
            >
              {matches ? 'NFT Pixel Canvas' : 'NPC'}
            </Link>
          </Typography>

          <Box
            sx={{ display: { xs: 'none', md: 'flex' }, mr: { xs: 0, md: 8 } }}
          >
            <Typography
              variant="body1"
              noWrap
              sx={{ mx: { xs: 2, md: 4 } }}
              component="div"
            >
              <Link
                component={RouteLink}
                to={ROUTE_TO_COLLECTION_PAGE + (account ? `/${account}` : '')}
                sx={{ textDecoration: 'none', color: 'white' }}
              >
                Collection
              </Link>
            </Typography>

            <Typography
              variant="body1"
              noWrap
              sx={{ mx: { xs: 2, md: 4 } }}
              component="div"
            >
              <Link
                component={RouteLink}
                to={ROUTE_TO_ABOUT_PAGE}
                sx={{ textDecoration: 'none', color: 'white' }}
              >
                About
              </Link>
            </Typography>

            <Typography
              variant="body1"
              noWrap
              sx={{ mx: { xs: 2, md: 4 } }}
              component="div"
            >
              <Link
                component={RouteLink}
                to={ROUTE_TO_WARNING_PAGE}
                sx={{
                  textDecoration: 'none',
                  color: (theme) => theme.palette.primary.light,
                }}
              >
                Warning
              </Link>
            </Typography>
          </Box>
          <Web3Connect />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="navigation"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              background: '#193549',
            },
          }}
        >
          <Sidebar toggle={handleDrawerToggle} />
        </Drawer>
      </Box>
    </>
  );
};
