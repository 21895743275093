import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

interface CountdownBoxProps {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export const CountdownBox: React.FunctionComponent<CountdownBoxProps> = ({
  days,
  hours,
  minutes,
  seconds,
}) => {
  const isLast10Minutes = (endColor: string) => {
    const daysNum = parseInt(days, 10);
    const hoursNum = parseInt(hours, 10);
    const minutesNum = parseInt(minutes, 10);

    if (daysNum === 0 && hoursNum === 0 && minutesNum <= 9) {
      return endColor;
    }
    return 'white';
  };

  return (
    <Box
      sx={{
        backgroundColor: '#3f3f3f',
        py: 2,
        textAlign: 'center',
        border: '1px solid black',
        borderRadius: 2,
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <Typography
        variant="h4"
        component="div"
        sx={{ color: (theme) => isLast10Minutes(theme.palette.error.main) }}
      >
        {days}:{hours}:{minutes}:{seconds}
      </Typography>
    </Box>
  );
};
