import { Box, Grid, Link, Typography } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import StarIcon from '@mui/icons-material/Star';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FlagIcon from '@mui/icons-material/Flag';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SensorDoorIcon from '@mui/icons-material/SensorDoor';

import './timeline.css';
// import { NFT_PIXEL_CANVAS_CONTRACT } from 'src/constants/contractAddress';

export const TimelineComponent = () => {
  return (
    <Grid container sx={{ justifyContent: 'center', mt: { xs: 5, sm: 10 } }}>
      <Grid item xs={12} lg={9} xl={7} sx={{ textAlign: 'center' }}>
        <Typography
          variant="h4"
          component="div"
          sx={{
            fontWeight: 500,
          }}
        >
          Timeline
        </Typography>
      </Grid>
      <Grid item xs={12} lg={9} xl={7} sx={{ textAlign: 'center' }}>
        <Box className="row">
          <Box className="col-md-12">
            <Box className="timeline-main">
              <ul className="stepper stepper-vertical timeline colorful-timeline pl-0">
                <li>
                  <Box className="icon-container">
                    <span className="circle red darken-2 z-depth-1-half">
                      <PublicIcon
                        style={{
                          marginTop: '-5px',
                          verticalAlign: 'middle',
                        }}
                      />
                    </span>
                  </Box>
                  <Box className="step-content z-depth-1 ml-2 p-0 mt-2">
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 0,
                        mb: 0.5,
                        lineHeight: 1.2,
                        fontSize: '1.5rem',
                      }}
                      className="font-weight-bold timeline-header red darken-2 white-text p-3"
                    >
                      Public announcement
                    </Typography>
                    <p className="text-muted mt-4 px-4">
                      <AccessAlarmIcon
                        style={{
                          verticalAlign: 'bottom',
                        }}
                      />{' '}
                      2021-10-23 19:30 UTC
                    </p>
                    <p className="mb-0 px-4 pb-4">
                      NFT Pixel Canvas website goes public. Follow us on{' '}
                      <Link
                        href="https://twitter.com/NFTPixelCanvas"
                        target="_blank"
                      >
                        Twitter
                      </Link>{' '}
                      for more information.{' '}
                      <Typography color="primary" component="span">
                        #NFTPixelCanvas
                      </Typography>
                    </p>
                    {/* {NFT_PIXEL_CANVAS_CONTRACT && (
                      <p className="mb-0 px-4 pb-4">
                        Contract address: {NFT_PIXEL_CANVAS_CONTRACT}
                      </p>
                    )} */}
                  </Box>
                </li>

                <li className="timeline-inverted">
                  <Box className="icon-container">
                    <span className="circle red darken-2 z-depth-1-half">
                      <ConfirmationNumberIcon
                        style={{
                          marginTop: '-5px',
                          verticalAlign: 'middle',
                        }}
                      />
                    </span>
                  </Box>

                  <Box className="step-content z-depth-1 mr-xl-2 p-0 mt-2">
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 0,
                        mb: 0.5,
                        lineHeight: 1.2,
                        fontSize: '1.5rem',
                      }}
                      className="font-weight-bold timeline-header red darken-2 white-text p-3 mb-0"
                    >
                      Win a free pre-reservation ticket
                    </Typography>
                    <p className="mt-4 px-4">
                      We are giving a total of 100 free pre-reservation {'"'}
                      Pixel Box{'"'} tickets to 100 lucky winners! To enter they
                      must share a public post mentioning #NFTPixelCanvas for
                      more than 48 hours including their Ethereum address and id
                      from [1, 9216] in the format `address:id` example: {'"'}
                      0xdeadbeef...ff:1337
                      {'"'}
                    </p>
                    <p className="mt-4 px-4">
                      Participants{"'"} posts will be hashed and combined with
                      our server seed, then sorted. The lowest 100 hashes are
                      the winners.
                    </p>
                    <p className="mt-4 px-4">
                      Server seed hash:{' '}
                      {process.env.REACT_APP_PRE_RESERVATION_SERVER_SEED_HASH}
                    </p>
                  </Box>
                </li>
                <li>
                  <Box className="icon-container">
                    <span className="circle red darken-2 z-depth-1-half">
                      <StarIcon
                        style={{
                          marginTop: '-5px',
                          verticalAlign: 'middle',
                        }}
                      />
                    </span>
                  </Box>

                  <Box className="step-content z-depth-1 ml-2 p-0 mt-2">
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 0,
                        mb: 0.5,
                        lineHeight: 1.2,
                        fontSize: '1.5rem',
                      }}
                      className="font-weight-bold timeline-header red darken-2 white-text p-3 mb-0"
                    >
                      Reservation phase starts
                    </Typography>
                    <p className="text-muted mt-4 px-4">
                      <AccessAlarmIcon
                        style={{
                          verticalAlign: 'bottom',
                        }}
                      />{' '}
                      2021-11-01 19:30 UTC
                    </p>
                    <p className="mb-0 px-4 pb-4">
                      Reservation gates will open. Anyone can reserve up to 10
                      Pixel Boxes per ethereum address.
                    </p>
                  </Box>
                </li>
                <li className="timeline-inverted">
                  <Box className="icon-container">
                    <span className="circle red darken-2 z-depth-1-half">
                      <MeetingRoomIcon
                        style={{
                          marginTop: '-5px',
                          verticalAlign: 'middle',
                        }}
                      />
                    </span>
                  </Box>

                  <Box className="step-content z-depth-1 mr-xl-2 p-0 mt-2">
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 0,
                        mb: 0.5,
                        lineHeight: 1.2,
                        fontSize: '1.5rem',
                      }}
                      className="font-weight-bold timeline-header red darken-2 white-text p-3 mb-0"
                    >
                      Reservations are open for 24h
                    </Typography>
                    <p className="mt-4 px-4">
                      At this point reservations are open for 24h. Anyone can
                      reserve part of the canvas for minting in the next phase.
                      Keep in mind both the reservation mint phase and public
                      mint phase requires <strong>0.05 ETH</strong> to
                      successfully mint the NFT.
                    </p>
                  </Box>
                </li>
                <li>
                  <Box className="icon-container">
                    <span className="circle red darken-2 z-depth-1-half">
                      <StarIcon
                        style={{
                          marginTop: '-5px',
                          verticalAlign: 'middle',
                        }}
                      />
                    </span>
                  </Box>

                  <Box className="step-content z-depth-1 ml-2 p-0 mt-2">
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 0,
                        mb: 0.5,
                        lineHeight: 1.2,
                        fontSize: '1.5rem',
                      }}
                      className="font-weight-bold timeline-header red darken-2 white-text p-3 mb-0"
                    >
                      The presale mint phase starts
                    </Typography>
                    <p className="text-muted mt-4 px-4">
                      <AccessAlarmIcon
                        style={{
                          verticalAlign: 'bottom',
                        }}
                      />{' '}
                      2021-11-02 19:30 UTC
                    </p>
                    <p className="mb-0 px-4 pb-4">
                      Reservation gates are closed. Once reservations are
                      closed, presale mint starts and lasts for 24h.
                    </p>
                  </Box>
                </li>
                <li className="timeline-inverted">
                  <Box className="icon-container">
                    <span className="circle red darken-2 z-depth-1-half">
                      <SensorDoorIcon
                        style={{
                          marginTop: '-5px',
                          verticalAlign: 'middle',
                        }}
                      />
                    </span>
                  </Box>

                  <Box className="step-content z-depth-1 mr-xl-2 p-0 mt-2">
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 0,
                        mb: 0.5,
                        lineHeight: 1.2,
                        fontSize: '1.5rem',
                      }}
                      className="font-weight-bold timeline-header red darken-2 white-text p-3 mb-0"
                    >
                      The presale mint is open for 24h
                    </Typography>
                    <p className="mt-4 px-4">
                      Reservations are closed. The final Merkle root is added to
                      the contract. Everyone can mint their pixel boxes with
                      reservation tickets for 24 hours.
                    </p>
                    <p className="mt-4 px-4">
                      Tokens that weren{"'"}t minted in the reservation mint
                      phase, will be available for minting in the next phase.
                    </p>
                  </Box>
                </li>
                <li>
                  <Box className="icon-container">
                    <span className="circle red darken-2 z-depth-1-half">
                      <FlagIcon
                        style={{
                          marginTop: '-5px',
                          verticalAlign: 'middle',
                        }}
                      />
                    </span>
                  </Box>

                  <Box className="step-content z-depth-1 ml-2 p-0 mt-2">
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 0,
                        mb: 0.5,
                        lineHeight: 1.2,
                        fontSize: '1.5rem',
                      }}
                      className="font-weight-bold timeline-header red darken-2 white-text p-3 mb-0"
                    >
                      NFT Pixel Canvas Final Form
                    </Typography>
                    <p className="text-muted mt-4 px-4">
                      <AccessAlarmIcon
                        style={{
                          verticalAlign: 'bottom',
                        }}
                      />{' '}
                      2021-11-03 19:30 UTC
                    </p>
                    <p className="mb-0 px-4 pb-4">
                      The contract goes into its final form. If there is any
                      pixel box left, it will be available to anyone for minting
                      - no reservation ticket is needed.
                    </p>
                  </Box>
                </li>
                {/* <li>
                  <Box className="icon-container">
                    <span className="circle red darken-2 z-depth-1-half">
                      <FlagIcon
                        style={{
                          marginTop: '-5px',
                          verticalAlign: 'middle',
                        }}
                      />
                    </span>
                  </Box>

                  <Box className="step-content z-depth-1 ml-2 p-0 mt-2">
                    <Typography variant="h5" sx={{ mt: 0 }} className="font-weight-bold timeline-header red darken-2 white-text p-3 mb-0">
                      Far in the future..., mb: 0.5, lineHeight: 1.2, fontSize: '1.5rem'
                    </Typography>
                  </Box>
                </li> */}
              </ul>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
