import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { Instagram, Telegram, Twitter } from '@mui/icons-material';
import React from 'react';

export const Footer = () => {
  return (
    <Grid
      container
      sx={{
        marginTop: 'auto',
        backgroundColor: '#15232d',
        mt: 5,
        minHeight: 100,
        py: { xs: 3, sm: 5 },
        px: { xs: 4, sm: 6 },
      }}
    >
      <Grid item xs={12}>
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid
            xs={12}
            lg={11}
            xl={9}
            item
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Box sx={{ minWidth: 200 }}>
              <Typography variant="body2" component="div">
                {new Date().getFullYear()} © nftpixelcanvas.com
              </Typography>
            </Box>
            <Box sx={{ display: 'inline-flex', gap: 1 }}>
              <Tooltip
                title={
                  <Typography variant="body2" sx={{ fontWeight: 400 }}>
                    Twitter
                  </Typography>
                }
                placement="top"
                arrow
              >
                <IconButton>
                  <Link
                    href="https://twitter.com/NFTPixelCanvas"
                    target="_blank"
                    sx={{ display: 'inline-flex' }}
                  >
                    <Twitter />
                  </Link>
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  <Typography variant="body2" sx={{ fontWeight: 400 }}>
                    Instagram
                  </Typography>
                }
                placement="top"
                arrow
              >
                <IconButton>
                  <Link
                    href="https://instagram.com/nftpixelcanvas"
                    target="_blank"
                    sx={{ display: 'inline-flex' }}
                  >
                    <Instagram />
                  </Link>
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  <Typography variant="body2" sx={{ fontWeight: 400 }}>
                    NFT Pixel Canvas Telegram Official Channel
                  </Typography>
                }
                placement="top"
                arrow
              >
                <IconButton>
                  <Link
                    href="https://t.me/nftpixelcanvas"
                    target="_blank"
                    sx={{ display: 'inline-flex' }}
                  >
                    <Telegram />
                  </Link>
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  <Typography variant="body2" sx={{ fontWeight: 400 }}>
                    NFT Pixel Canvas Telegram Chat
                  </Typography>
                }
                placement="top"
                arrow
              >
                <IconButton>
                  <Link
                    href="https://t.me/nftpixelcanvaschat"
                    target="_blank"
                    sx={{ display: 'inline-flex' }}
                  >
                    <Telegram />
                  </Link>
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
