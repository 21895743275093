export const NetworkContextName = 'NETWORK';

export const INITIAL_TOKEN_DATA = Array(100).fill('ffffff');

export const MINT_VALUE = process.env.REACT_APP_MINT_VALUE || '0.05';

const COUNTDOWN_TIME = process.env.REACT_APP_COUNTDOWN_TO_RESERVE
  ? parseInt(process.env.REACT_APP_COUNTDOWN_TO_RESERVE, 10)
  : 1635795000000;

// export const COUNTDOWN_TIME = Date.now();
// export const RESERVATION_PERIOD = 1000;

const RESERVATION_PERIOD = process.env.REACT_APP_RESERVATION_PERIOD
  ? parseInt(process.env.REACT_APP_RESERVATION_PERIOD, 10)
  : 86400000;

export const RESERVATION_START_TIME = COUNTDOWN_TIME;
export const RESERVATION_END_TIME = COUNTDOWN_TIME + RESERVATION_PERIOD;
export const RESERVATION_MINT_START_TIME = COUNTDOWN_TIME + RESERVATION_PERIOD;
export const RESERVATION_MINT_END_TIME =
  RESERVATION_MINT_START_TIME + RESERVATION_PERIOD;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';
export const DATA_ENDPOINT = process.env.REACT_APP_DATA_ENDPOINT || '';
