import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';

interface ErrorBoundaryMessageProps {
  error: Error;
  componentStack?: string | null;
  eventId?: string | null;
  resetError(): void;
}

export const ErrorBoundaryMessage: React.FunctionComponent<ErrorBoundaryMessageProps> =
  ({ error, resetError }) => {
    useEffect(() => {
      // re-send error if Adblock block original
      Sentry.captureException({
        exception: error,
      });
    }, [error]);

    return (
      <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
          <Typography variant="h3" component="div" gutterBottom>
            An error occurred!
          </Typography>
          <Typography variant="body1" component="div" sx={{ mb: 4 }}>
            We are sorry for the inconvenience.
          </Typography>
          <Button variant="contained" onClick={resetError}>
            Go back
          </Button>
        </Grid>
      </Grid>
    );
  };
