import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useState } from 'react';
import { ColorChangeHandler, ChromePicker } from 'react-color';
import {
  arrayBufferToHex,
  generateInitialPixelData,
  readFileAsArrayBuffer,
} from 'src/utils/hex';
import { ColorBox } from './components/ColorBox';
import { INITIAL_TOKEN_DATA } from 'src/constants/misc';

interface CustomizablePixelBoxProps {
  setTokenData: (value: React.SetStateAction<string[]>) => void;
  pixelColors: string[];
  resetChanges: () => void;
  pixelId?: string;
  mint?: boolean;
  setFromInitialSeed?: React.Dispatch<React.SetStateAction<boolean>>;
  fromInitialSeed?: boolean;
}

const useStyles = makeStyles({
  pixelBox: {
    height: 225,
    width: 225,
    display: 'flex',
    flexWrap: 'wrap',
    userSelect: 'none',
    marginRight: 32,
    marginBottom: 32,
  },
});

export const CustomizablePixelBox: React.FunctionComponent<CustomizablePixelBoxProps> =
  ({
    setTokenData,
    pixelColors,
    resetChanges,
    pixelId,
    mint = false,
    setFromInitialSeed,
    fromInitialSeed,
  }) => {
    const classes = useStyles();
    const [color, setColor] = useState<string>('#ffffff');

    const handleToggleInitialSeed = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (typeof setFromInitialSeed === 'function' && pixelId) {
        if (event.target.checked) {
          setTokenData([...generateInitialPixelData(pixelId)]);
        } else {
          setTokenData([...INITIAL_TOKEN_DATA]);
        }
        setFromInitialSeed(event.target.checked);
      }
    };

    const handleChange: ColorChangeHandler = ({ hex }) => {
      setColor(hex);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event?.target?.files) {
        if (event.target.files[0]) {
          if (typeof setFromInitialSeed === 'function') {
            setFromInitialSeed(false);
          }
          readFileAsArrayBuffer(event.target.files[0]).then((arrayBuffer) => {
            const imageHex = arrayBufferToHex(arrayBuffer);
            if (imageHex) {
              setTokenData([...imageHex]);
            }
          });
        }
      }
    };

    const handlePixelClick = useCallback(
      (index: number) => {
        setTokenData((pixelColors) => {
          const tokenData = pixelColors;
          tokenData[index] = color.replace('#', '');
          return [...tokenData];
        });
      },
      [color, setTokenData]
    );

    const renderPixels = () => {
      return [...Array(100).keys()].map((_, index) => (
        <ColorBox
          key={index}
          color={`#${pixelColors[index]}`}
          onClick={(event: React.MouseEvent) => {
            if (event.buttons === 1) {
              handlePixelClick(index);
            }
          }}
          onTouch={() => handlePixelClick(index)}
        />
      ));
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          {pixelId && (
            <Typography
              variant="body2"
              noWrap
              component="div"
              sx={{
                width: 225,
                height: 20,
                marginBottom: 0.5,
                textAlign: 'center',
              }}
            >
              Pixel Box #{pixelId}
            </Typography>
          )}
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className={classes.pixelBox}>{renderPixels()}</div>
            <Box sx={{ mb: 4, height: 225 }}>
              <ChromePicker
                disableAlpha
                color={color}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mb: { xs: 0, sm: 2 } }}>
          <Button
            variant="contained"
            color="primary"
            onClick={
              !mint
                ? resetChanges
                : () => {
                    if (typeof setFromInitialSeed === 'function') {
                      setFromInitialSeed(false);
                    }
                    resetChanges();
                  }
            }
            sx={{ mr: 3, mb: { xs: 2, sm: 0 } }}
          >
            Reset
          </Button>
          <FormLabel htmlFor={`contained-button-file-${pixelId}`}>
            <Input
              sx={{ display: 'none' }}
              inputProps={{
                accept: 'image/*',
              }}
              id={`contained-button-file-${pixelId}`}
              type="file"
              onChange={handleFileChange}
              onClick={(event) => {
                (event.target as HTMLInputElement).value = '';
              }}
            />
            <Button
              variant="contained"
              color="secondary"
              component="span"
              sx={{ mr: 3, mb: { xs: 2, sm: 0 } }}
            >
              Color from image
            </Button>
          </FormLabel>
          {mint && (
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                verticalAlign: 'middle',
                mb: { xs: 2, sm: 0 },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={fromInitialSeed}
                    onChange={handleToggleInitialSeed}
                  />
                }
                label={
                  <Typography variant="body2" sx={{ fontWeight: 400 }}>
                    Use initial Pixel Box data
                  </Typography>
                }
                sx={{ mr: 1 }}
              />
              <Tooltip
                title={
                  <Typography variant="body2" sx={{ fontWeight: 400 }}>
                    Checking this option will make the transaction cheaper, but
                    the Pixel Box will be minted with random colors derived from
                    the initial seed.
                  </Typography>
                }
                placement="top"
                arrow
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Link color="secondary" href="/img/sample.bmp" download>
            Download sample image
          </Link>
        </Grid>
      </Grid>
    );
  };
