import { Box, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { generateSingleDataURL } from 'src/nftpixelcanvas-sdk';
import { Token } from 'src/types';
import { EthereumSymbol } from '../EthereumSymbol';
import { TextWithLinks } from '../TextWithLinks';
interface SinglePixelDisplayProps {
  token: Token;
  link?: boolean;
}

const useStyles = makeStyles({
  pixelContainer: {
    display: 'inline-block',
    alignItems: 'center',
    textAlign: 'center',
    width: 186,
    margin: '0 24px 36px',
  },
  pixelWrapper: {
    backgroundColor: '#0C1720',
    padding: 28,
    height: 186,
  },
});

export const SinglePixelDisplay: React.FunctionComponent<SinglePixelDisplayProps> =
  ({ token, link = false }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const textRef = useRef<HTMLDivElement | null>(null);

    const handleOpen = () => {
      if (
        textRef?.current &&
        textRef.current.clientWidth < textRef.current.scrollWidth
      ) {
        setOpen(true);
      }
    };

    const handleClose = () => {
      setOpen(false);
    };

    const imageData = generateSingleDataURL(token.imageData, 13);

    const renderImageData = () => (
      <div className={classes.pixelWrapper}>
        <img src={imageData} />
      </div>
    );

    return (
      <div className={classes.pixelContainer}>
        <Typography
          variant="body2"
          noWrap
          component="div"
          sx={{ width: '100%', height: 20, marginBottom: 0.5 }}
        >
          Pixel Box #{token.id}
        </Typography>
        {link ? (
          <Link to={`/token/${token.id}`}>{renderImageData()}</Link>
        ) : (
          renderImageData()
        )}
        <div>
          <Tooltip
            title={<TextWithLinks tokenText={token.text} />}
            placement="top"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
          >
            <Typography
              ref={textRef}
              variant="body2"
              noWrap
              component="div"
              sx={{
                width: '100%',
                height: 20,
                marginTop: 0.5,
              }}
            >
              <TextWithLinks tokenText={token.text} />
            </Typography>
          </Tooltip>

          {token.value && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 0.5,
                minHeight: 24,
              }}
            >
              <Typography
                variant="body1"
                noWrap
                component="div"
                sx={{ mr: 0.5 }}
              >
                {token.value}
              </Typography>
              <EthereumSymbol />
            </Box>
          )}
        </div>
      </div>
    );
  };
