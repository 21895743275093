import { IconButton, InputBase, Paper } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

interface SearchInputProps {
  searchValue: string | undefined;
  onChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const SearchInput: React.FunctionComponent<SearchInputProps> = ({
  searchValue,
  onChange,
  onClick,
}) => {
  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 600 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search address"
        inputProps={{ 'aria-label': 'search address' }}
        value={searchValue}
        onChange={onChange}
      />
      <IconButton
        type="submit"
        sx={{ p: '10px' }}
        aria-label="search"
        onClick={onClick}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
