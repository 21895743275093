import { Grid, Link as MuiLink, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import okaidia from './custom-syntax-styling';
import { ROUTE_TO_HOME_PAGE } from 'src/constants/routes';
// import { NFT_PIXEL_CANVAS_CONTRACT } from 'src/constants/contractAddress';

// const SEED = process.env.REACT_APP_SEED;

export const AboutPage = () => {
  return (
    <Grid container sx={{ marginBottom: 4 }}>
      <Helmet titleTemplate="NFT Pixel Canvas | %s">
        <title>About</title>
      </Helmet>
      <Grid item xs={12}>
        <Box
          sx={{ maxWidth: '910px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <Typography mt={2} variant="h3">
            About NFT Pixel Canvas
          </Typography>
          {/* <Typography mt={4} variant="body1">
          We will assume that you already know what NFT&apos;s are if not please{' '}
          <Link
            href="https://en.wikipedia.org/wiki/Non-fungible_token"
            target="_blank"
            sx={{ textDecoration: 'none' }}
          >
            read here
          </Link>
          .
        </Typography>
        <Typography mt={4} variant="subtitle1">
          NFT Pixel Canvas enables owners of the token to control part of the
          public canvas and invest/store the value inside of it.
        </Typography>
        <Typography mt={4} variant="body1">
          NFT Pixel Canvas is a 1280 x 720 pixels canvas that is split into 9216
          unique pixel boxes. The canvas is initialized to the pseudo-random
          state with a seed &quot;
          {SEED}
          &quot;.
        </Typography>
        <Typography mt={4} variant="body1">
          {`The owner of the token can edit the color of each of the 100 pixels in the
          corresponding pixel box, and can edit the text. All the data is
          written to the blockchain. NFT Pixel Canvas is different from other
          NFT's by offering a special feature known as "Hostile takeover" by
          offering anyone to buy out the pixel by offering 20% more than the
          last known highest price. The owner will receive 16% of the profit
          including the initial investment, the developers take 4% commisions on
          the profit. We can better explain this with a simple example. Meet
          Bob, and Elon, Bob minted pixelboxes early on and sold them on the
          market, Elon saw the NPC from a fan sharing the idea in a tweet. Bob
          owns PixelBox with id 420 and the last known price was 100.0 ETH. Elon
          really wants that pixel box and uses the contract feature "Hostile
          takeover" to pay 120.0 ETH to the contract for the pixel box id 420,
          Bob has no choice, he automatically receives 116 ETH, the originally
          invested 100.0 ETH and 16.0 ETH of profit. At this point Elon has control
          over the colors of the pixel box and the related text. He can update them
          or choose to leave it as is. Bob can retake the pixel box with id 420 by
          using the same feature but offering 144 ETH. Elon laughs because he
          knows not even his friend Jeff has that amount and is safe from takeover.`}
        </Typography> */}

          <Typography mt={4} variant="body1">
            NFT Pixel Canvas is a collection of 9216 NFT Pixel Boxes, each
            controlling 10x10 pixels on the public canvas that the owner can
            edit, change color, add text or links and earn ETH.
          </Typography>

          <Typography mt={4} variant="body1">
            This NFT aims to live forever, to be alive for the years and years
            to come, it has an innovative feature built into the contract itself
            called {'"'}buyout{'"'} that enable users to buy out part of the
            canvas by paying owners of existing NFT minimum of 16% more than the
            last buyout price.
          </Typography>

          <Typography mt={4} variant="body1">
            What that means is that the worth of each token is in a way
            reasonably defined by the contract, it can be traded for more or
            less, but the actual worth value in the state of the contract
            defines at which price the token can be bought from existing token
            owner. The owner of the token in that case will receive the
            originally invested amount plus a minimum of 16% profit. Please
            refer to the Warning section on the page to be fully aware of what
            that means in practice. Token owners can also increase the value of
            the token they own by using the same functionality on their tokens.
          </Typography>

          <Typography mt={4} variant="body1">
            The public canvas that is made from the collection of 9216 Pixel
            Boxes has dimensions of 1280 x 720 px, 921600 pixels in total. Token
            owners can edit the text and use links to advertise their brand,
            project, or webpage.
          </Typography>

          <Typography mt={4} variant="h5">
            Important keynotes:
          </Typography>

          <ul>
            <li>NFT Pixel Canvas is a collection of 9216 NFTs!</li>
            <li>NEW Buyout feature (You can buy out parts of the canvas)!</li>
            <li>921600 pixels in total, 1280 x 720 px! (Standard HD 720p)</li>
            <li>Add your advertisement, text, links to your site!</li>
            <li>Control the color of the pixels you own!</li>
            <li>
              Always live, never-ending, final contract (no-contract delegate
              calls, what you see is what you get)
            </li>
          </ul>

          <Typography mt={4} variant="h5">
            Follow us on official channels:
          </Typography>
          <Typography mt={4} variant="body1" component="div">
            <ul>
              <li>
                Official webpage:{' '}
                <MuiLink component={Link} to={ROUTE_TO_HOME_PAGE}>
                  https://www.nftpixelcanvas.com
                </MuiLink>
              </li>
              <li>
                Twitter{' '}
                <MuiLink
                  href="https://twitter.com/NFTPixelCanvas"
                  target="_blank"
                >
                  https://twitter.com/NFTPixelCanvas
                </MuiLink>
              </li>
              <li>
                Telegram{' '}
                <MuiLink href="https://t.me/nftpixelcanvas" target="_blank">
                  https://t.me/nftpixelcanvas
                </MuiLink>
              </li>
              <li>
                Telegram Channel Chat:{' '}
                <MuiLink href="https://t.me/nftpixelcanvaschat" target="_blank">
                  https://t.me/nftpixelcanvaschat
                </MuiLink>
              </li>
            </ul>
          </Typography>

          <Typography mt={4} variant="h5">
            Tech details
          </Typography>

          <Typography mt={4} variant="body1">
            NFT Pixel Canvas (NPC for short) is a public standard HD 720p
            canvas, 1280×720 px (0.9 megapixels), and an aspect ratio (AR) of
            16:9 with additional text and price-worth metadata, both the color
            and the text can be updated by the owners of a token. Each token
            controls in a total of 100 pixels, the actual 10x10px blocks called
            pixel boxes (PB for short), because they also contain metadata,
            text, and worth value.
          </Typography>

          <Typography mt={4} variant="body1" sx={{ wordBreak: 'break-word' }}>
            {/* {NFT_PIXEL_CANVAS_CONTRACT &&
              `The NFT token contract is published on the Ethereum network. The
            contract address is ${NFT_PIXEL_CANVAS_CONTRACT}. `} */}
            To mint the tokens you need either a valid Merkle proof that you can
            receive in the reservation phase or by using the mint method after
            the reservation phase for the available tokens. In both cases, the
            initial mint price is 0.05 ETH
          </Typography>

          <Typography mt={4} variant="body1">
            Once minted they are owned by the token owner and can be transferred
            as any ERC721 token but anyone at any time can buy out the token by
            using the buyout method and paying 20% more of the last buyout
            value. The owner will receive a 16% profit (20% - 4% royalty fee)
            and can retake the ownership of the token by using the same buyout
            method.
          </Typography>

          <Typography mt={4} variant="body1" sx={{ wordBreak: 'break-word' }}>
            The initial image state of the canvas is initialized to the pseudo
            random method by expanding the initial seed
            0xd33b8c65a611141df25e83607423cec5b75e30a68bbf5df994f815eb80be6587
            using hmacSha256 with initial seed as a key and zero-padded index of
            the PB starting from 1 to 9216.
          </Typography>

          <Typography mt={4} variant="body2" component="div">
            <SyntaxHighlighter
              style={okaidia}
              language="javascript"
            >{`const hmacSha256 = (inputKey, inputString) => {
  return crypto.createHmac('sha256', inputKey).update(inputString).digest('hex');
};
  
for (let i = 1 ; i <= totalAmountOfPixelBoxes; i++) {
  const pixelBoxIndexNumber = (\`\${i}\`).padStart(4, '0');
  const pixelBoxSeed = hmacSha256(initSeed, \`\${i}\`);
  console.log(pixelBoxIndexNumber, pixelBoxSeed);
}`}</SyntaxHighlighter>
          </Typography>

          <Typography mt={4} variant="body1">
            thus receiving a new set of seeds for every PB. Every seed is then
            expanded by hashing concatenated results until 320 bytes of
            pseudo-random data is generated, the first 300 bytes are the value
            used for the image state if the user who owns the token does not
            change the value of the image.
          </Typography>

          <Typography mt={4} variant="body1">
            The snippet of the code is here:
          </Typography>

          <Typography mt={4} variant="body2" component="div">
            <SyntaxHighlighter
              style={okaidia}
              language="javascript"
            >{`import crypto from 'crypto';

const sha256 = (inputString) => {
  return crypto.createHash('sha256').update(inputString).digest('hex');
};

const hmacSha256 = (inputKey, inputString) => {
  return crypto.createHmac('sha256', inputKey).update(inputString).digest('hex');
};

const usingInitialStateExpandData = (startState = '') => {
  let state = [];
  for (let i = 0; i < 10; i++) {
    const stateToString = state.join('')
    const toHash = (stateToString === '') ? startState : stateToString;
    state.push(sha256(\`\${toHash}\`))
  }
  // 20 bytes too much on 10th iteration, we just need 300 bytes for the 10x10px
  return state.join('');
};

const totalAmountOfPixelBoxes = 9216;

for (let i = 1 ; i <= totalAmountOfPixelBoxes; i++) {
  const pixelBoxIndexNumber = (\`\${i}\`).padStart(4, '0');
  const pixelBoxSeed = hmacSha256('d33b8c65a611141df25e83607423cec5b75e30a68bbf5df994f815eb80be6587', \`\${i}\`);
  console.log(pixelBoxIndexNumber, pixelBoxSeed);

  const pregeneratedData = usingInitialStateExpandData(pixelBoxSeed);

  const data = Buffer.from(pregeneratedData, 'hex').slice(0, 300);
  console.log(data);
}
            `}</SyntaxHighlighter>
          </Typography>

          <Typography mt={4} variant="body1">
            The canvas can be rendered on the official page{' '}
            <MuiLink component={Link} to={ROUTE_TO_HOME_PAGE}>
              https://www.nftpixelcanvas.com
            </MuiLink>{' '}
            directly from the data on the Ethereum blockchain. Additionally, the
            canvas is also server-side rendered every 15 minutes and can be
            found publicly available at{' '}
            <MuiLink
              href="https://data.nftpixelcanvas.com/canvas.png"
              target="_blank"
            >
              https://data.nftpixelcanvas.com/canvas.png
            </MuiLink>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
