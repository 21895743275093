import { Grid, Link, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Link as RouteLink } from 'react-router-dom';
import { ROUTE_TO_HOME_PAGE } from 'src/constants/routes';

export const WarningPage = () => {
  return (
    <Grid container>
      <Helmet titleTemplate="NFT Pixel Canvas | %s">
        <title>Warning</title>
      </Helmet>
      <Grid item xs={12}>
        <Box
          sx={{ maxWidth: '910px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <Typography mt={2} variant="h3">
            Warning while trading the Pixel Boxes
          </Typography>
          <Typography variant="body1" sx={{ mt: 4 }}>
            Please be careful. NPCPB (NFT Pixel Canvas Pixel Box) is a new type
            of NFT. Value of every Pixel Box is defined by the contract.
            Meaning, you should always be aware of the value of the specific
            NPCPB when buying on marketplaces like OpenSea or in-person, etc.
          </Typography>
          <Typography variant="body1" sx={{ mt: 3 }}>
            For example, if the NPCPB:420 is worth 12.0 ETH because the last
            &quot;buyout&quot; event has increased the worth from 10.0 ETH to
            12.0 ETH it does not make sense to buy it from someone else for more
            than 14.4 ETH. Because the seller could retake the NPCPB:420 again
            for 14.4 ETH.
          </Typography>
          <Typography variant="body1" sx={{ mt: 3 }}>
            We update the value to metadata regulary but we suggest to check the
            exact price on blockchain preferably using{' '}
            <Link
              component={RouteLink}
              to={ROUTE_TO_HOME_PAGE}
              sx={{ textDecoration: 'none' }}
            >
              https://www.nftpixelcanvas.com
            </Link>{' '}
            interface for the Pixel Box you are interested.
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            As long as you are aware and understand that only the
            &quot;buyout&quot; method increases the value of a specific Pixel
            Box you should be good. Have fun!
          </Typography>
          <hr />
          <Typography variant="caption" color="#d8d6d6">
            <ul>
              <li>NPC - NFT Pixel Canvas</li>
              <li>
                Pixel Box - One of 9216 collectable NFTs, a 10x10 pixel box that
                is part of the canvas, which the owner can control and use it as
                advertisment space.
              </li>
              <li>NPCPB:420 - NFT Pixel Canvas Pixel Box with id 420</li>
            </ul>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
