/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Footer } from 'src/components/Footer';
import { Header } from 'src/components/Header';
import {
  ROUTE_TO_ABOUT_PAGE,
  ROUTE_TO_WARNING_PAGE,
  ROUTE_TO_COLLECTION_PAGE,
  ROUTE_TO_HOME_PAGE,
  ROUTE_TO_TOKEN_PAGE,
  ROUTE_TO_RESERVATION_PAGE,
  ROUTE_TO_RESERVED_MINT_PAGE,
} from 'src/constants/routes';
import { AboutPage } from 'src/views/AboutPage';
import { CollectionPage } from 'src/views/CollectionPage';
import { HomePage } from 'src/views/HomePage';
import { TokenPage } from 'src/views/TokenPage';
import { WarningPage } from 'src/views/WarningPage';
// import { InDevelopmentPage } from 'src/views/InDevelopmentPage';
const ReservationPage = import('src/views/ReservationPage');
const ReservationPageRoute = React.lazy(() =>
  ReservationPage.then((module) => ({ default: module.ReservationPage }))
);
const ReservedMintPage = import('src/views/ReservedMintPage');
const ReservedMintPageRoute = React.lazy(() =>
  ReservedMintPage.then((module) => ({ default: module.ReservedMintPage }))
);

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export const RoutingLayout = () => {
  return (
    <>
      <Header />
      <Main>
        <DrawerHeader />

        <Container maxWidth={false}>
          <Switch>
            {/* <Route
              exact
              strict
              path={ROUTE_TO_HOME_PAGE}
              component={InDevelopmentPage}
            /> */}
            <Route
              exact
              strict
              path={ROUTE_TO_HOME_PAGE}
              component={HomePage}
            />
            <Route
              exact
              strict
              path={`${ROUTE_TO_TOKEN_PAGE}/:tokenId`}
              component={TokenPage}
            />
            <Route
              exact
              strict
              path={`${ROUTE_TO_COLLECTION_PAGE}/:address?`}
              component={CollectionPage}
            />
            <Route
              exact
              strict
              path={ROUTE_TO_ABOUT_PAGE}
              component={AboutPage}
            />
            <Route
              exact
              strict
              path={ROUTE_TO_WARNING_PAGE}
              component={WarningPage}
            />

            <Route exact strict path={ROUTE_TO_RESERVATION_PAGE}>
              <React.Suspense fallback={null}>
                <ReservationPageRoute />
              </React.Suspense>
            </Route>

            <Route exact strict path={ROUTE_TO_RESERVED_MINT_PAGE}>
              <React.Suspense fallback={null}>
                <ReservedMintPageRoute />
              </React.Suspense>
            </Route>

            <Redirect to={ROUTE_TO_HOME_PAGE} />
          </Switch>
        </Container>
      </Main>
      <Footer />
    </>
  );
};
