import { Box, Button, Typography } from '@mui/material';
import { formatEther } from '@ethersproject/units';
import toformat from 'toformat';
import React, { useCallback, useEffect, useState } from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import _Decimal from 'decimal.js-light';
import { shortenAddress } from '../../utils';
import { WalletModal } from '../WalletModal';

const Decimal = toformat(_Decimal);

export const Web3Connect = () => {
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState();
  const { account, active, chainId, error, library } = useWeb3React();

  useEffect(() => {
    if (!!account && !!library) {
      let stale = false;

      library
        .getBalance(account)
        .then((balance: any) => {
          if (!stale) {
            setBalance(balance);
          }
        })
        .catch(() => {
          if (!stale) {
            setBalance(undefined);
          }
        });

      return () => {
        stale = true;
        setBalance(undefined);
      };
    }
  }, [account, library, chainId]);

  const handleToggleModal = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  const renderAddressOrConnect = () => {
    if (account) {
      return (
        <Button
          color="secondary"
          disableRipple
          onClick={handleToggleModal}
          sx={{
            py: 0.5,
            backgroundColor: '#191b1f',
            border: '1px solid',
            borderColor: (theme) => theme.palette.text.secondary,
            color: 'white',
            textAlign: 'left',
            textTransform: 'none',
            '&:hover': {
              borderColor: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Box>
            {account && (
              <Typography
                variant="body2"
                noWrap
                sx={{ flexGrow: 1 }}
                component="div"
              >
                Account: {shortenAddress(account)}
              </Typography>
            )}
            {balance && (
              <Typography
                variant="body2"
                noWrap
                sx={{ flexGrow: 1 }}
                component="div"
              >
                Balance: {new Decimal(formatEther(balance)).toFormat(2)} ETH
              </Typography>
            )}
          </Box>
        </Button>
      );
    }
    if (error) {
      return (
        <Box
          sx={{
            minWidth: 98,
            py: 0.5,
            px: 1,
            backgroundColor: (theme) => theme.palette.error.main,
            border: '2px solid',
            borderRadius: '4px',
            borderColor: (theme) => theme.palette.error.main,
            color: 'white',
            textAlign: 'center',
            textTransform: 'none',
            '&:hover': {
              borderColor: (theme) => theme.palette.error.dark,
            },
          }}
        >
          <Typography variant="body1">
            {error instanceof UnsupportedChainIdError
              ? 'Wrong Network'
              : 'Error'}
          </Typography>
        </Box>
      );
    }
    return (
      <Button variant="contained" color="primary" onClick={handleToggleModal}>
        CONNECT
      </Button>
    );
  };

  return (
    <Box>
      {renderAddressOrConnect()}
      {active && (
        <WalletModal
          walletModalOpen={open}
          toggleWalletModal={handleToggleModal}
        />
      )}
    </Box>
  );
};
