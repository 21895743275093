import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import React from 'react';

interface ModalCloseButtonProps {
  toggleModal: () => void;
}

export const ModalCloseButton: React.FunctionComponent<ModalCloseButtonProps> =
  ({ toggleModal }) => {
    return (
      <IconButton
        aria-label="close"
        onClick={toggleModal}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    );
  };
