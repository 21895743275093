import { Box, Button, Typography } from '@mui/material';
import { AbstractConnector } from '@web3-react/abstract-connector';
import React from 'react';
import { injected } from 'src/connectors';
import { Option } from './Option';
import { SUPPORTED_WALLETS } from 'src/constants/wallet';
import { CircularLoader } from 'src/components/CircularLoader';

interface PendingViewProps {
  connector?: AbstractConnector;
  error?: boolean;
  setPendingError: (error: boolean) => void;
  tryActivation: (connector: AbstractConnector) => void;
}

export const PendingView: React.FunctionComponent<PendingViewProps> = ({
  connector,
  error = false,
  setPendingError,
  tryActivation,
}) => {
  const isMetamask = window?.ethereum?.isMetaMask;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        '& > *': {
          width: '100%',
        },
      }}
    >
      <Typography
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: 3,
          borderRadius: 1,
          color: (theme) => (error ? theme.palette.error.main : 'inherit'),
          border: (theme) =>
            `1px solid ${
              error ? theme.palette.error.main : theme.palette.text.primary
            }`,
          '& > *': {
            padding: '1rem',
          },
        }}
      >
        <Box>
          {error ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Typography component="div">Error connecting</Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  ml: 2,
                  padding: 0.5,
                  textTransform: 'none',
                  fontSize: 12,
                }}
                onClick={() => {
                  setPendingError(false);
                  connector && tryActivation(connector);
                }}
              >
                Try Again
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularLoader size={18} />
              <Typography sx={{ ml: 2 }}>Initializing...</Typography>
            </Box>
          )}
        </Box>
      </Typography>
      {Object.keys(SUPPORTED_WALLETS).map((key) => {
        const option = SUPPORTED_WALLETS[key];
        if (option.connector === connector) {
          if (option.connector === injected) {
            if (isMetamask && option.name !== 'MetaMask') {
              return null;
            }
            if (!isMetamask && option.name === 'MetaMask') {
              return null;
            }
          }
          return (
            <Option
              id={`connect-${key}`}
              key={key}
              clickable={false}
              color={option.color}
              header={option.name}
              subheader={option.description}
              icon={option.iconURL}
            />
          );
        }
        return null;
      })}
    </Box>
  );
};
