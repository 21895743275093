import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { PermMedia, Home, MenuBook, WarningAmber } from '@mui/icons-material';
import React from 'react';
import {
  ROUTE_TO_ABOUT_PAGE,
  ROUTE_TO_COLLECTION_PAGE,
  ROUTE_TO_HOME_PAGE,
  ROUTE_TO_WARNING_PAGE,
} from 'src/constants/routes';

interface SidebarProps {
  toggle: () => void;
}

export const Sidebar: React.FunctionComponent<SidebarProps> = ({ toggle }) => {
  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItemButton
          component={NavLink}
          to={ROUTE_TO_HOME_PAGE}
          activeClassName="Mui-selected"
          exact
          onClick={toggle}
        >
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          to={ROUTE_TO_COLLECTION_PAGE}
          activeClassName="Mui-selected"
          exact
          onClick={toggle}
        >
          <ListItemIcon>
            <PermMedia />
          </ListItemIcon>
          <ListItemText primary="Collection" />
        </ListItemButton>
      </List>
      <Divider />
      <List>
        <ListItemButton
          component={NavLink}
          to={ROUTE_TO_ABOUT_PAGE}
          activeClassName="Mui-selected"
          exact
          onClick={toggle}
        >
          <ListItemIcon>
            <MenuBook />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          to={ROUTE_TO_WARNING_PAGE}
          activeClassName="Mui-selected"
          exact
          onClick={toggle}
        >
          <ListItemIcon>
            <WarningAmber />
          </ListItemIcon>
          <ListItemText primary="Warning" />
        </ListItemButton>
      </List>
    </div>
  );
};
