import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Token } from 'src/types';
import { CircularLoader } from '../CircularLoader';
import { SinglePixelDisplay } from '../SinglePixelDisplay';

interface PixelCollectionProps {
  tokens: Token[];
  isLoading: boolean;
  account?: string;
  error: string;
}

const useStyles = makeStyles({
  collectionContainer: {
    minHeight: 258,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  loaderContainer: {
    minHeight: 258,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const PixelCollection: React.FunctionComponent<PixelCollectionProps> = ({
  tokens,
  isLoading,
  account,
  error,
}) => {
  const classes = useStyles();

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularLoader />
        </div>
      );
    }

    if (error) {
      return <Typography sx={{ textAlign: 'center' }}>{error}</Typography>;
    }

    if (account && tokens.length < 1) {
      return (
        <Typography sx={{ textAlign: 'center' }}>
          The selected address does not hold any Pixel Boxes.
        </Typography>
      );
    }

    return (
      <div className={classes.collectionContainer}>
        {tokens.map((token, index) => (
          <SinglePixelDisplay key={index} token={token} link />
        ))}
      </div>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {renderContent()}
      </Grid>
    </Grid>
  );
};
