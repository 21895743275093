import Countdown, { CountdownRendererFn } from 'react-countdown';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Reservation } from './components/Reservation';
import { DATA_ENDPOINT, RESERVATION_START_TIME } from 'src/constants/misc';
import { CountdownBox } from './components/CountdownBox';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  canvasImage: {
    maxWidth: '100%',
    height: 'auto',
  },
});

export const CountdownToReservation = () => {
  const classes = useStyles();

  const renderer: CountdownRendererFn = ({
    completed,
    formatted: { days, hours, minutes, seconds },
  }) => {
    if (completed) {
      // Render a completed state
      return <Reservation />;
    } else {
      // Render a countdown
      return (
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid item xs={12} sm={10} md={8} lg={6} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" component="div" gutterBottom>
              Reservation phase starts in:
            </Typography>
            <CountdownBox
              days={days}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
            <img
              className={classes.canvasImage}
              src={`${DATA_ENDPOINT}/initial-state-d33b8c65a611141df25e83607423cec5b75e30a68bbf5df994f815eb80be6587.png`}
            />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      <Countdown date={RESERVATION_START_TIME} renderer={renderer} />
    </>
  );
};
