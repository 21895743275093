import { BigNumber } from '@ethersproject/bignumber';
import { formatEther, formatUnits } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Token } from 'src/types';
import { useNFTPixelCanvasContract } from './useContract';
import { sortPixelsFn } from 'src/utils/pixels';

export const useGetOwnedTokens = (account: string | null | undefined) => {
  const [tokens, setTokens] = useState<Token[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isFetched, setFetched] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const contract = useNFTPixelCanvasContract();

  useEffect(() => {
    const getOwnerTokens = async () => {
      const tokenList: Token[] = [];
      try {
        if (contract && account) {
          setLoading(true);
          const balance = await contract.balanceOf(account);

          if (!balance.isZero()) {
            for (let i = 0; i < parseInt(formatUnits(balance, 0)); i++) {
              const tokenId = await contract.tokenOfOwnerByIndex(account, i);

              const token = await contract.getDataOf(tokenId);
              const value = await contract.getWorthOf(tokenId);

              tokenList.push({
                id: tokenId.toString(),
                text: token[0],
                imageData: token[1].replace('0x', ''),
                value: formatEther(value).toString(),
              });
            }
          }
        }

        setTokens([...tokenList.sort(sortPixelsFn)]);
        setError(null);
        setLoading(false);
        setFetched(true);
      } catch (e) {
        setTokens([]);
        setError(e);
        setLoading(false);
        setFetched(true);
      }
    };

    getOwnerTokens();
  }, [contract, account]);

  useEffect(() => {
    const handleEditCollection = async (
      from: string,
      to: string,
      amount: BigNumber
    ) => {
      if (contract && account) {
        const tokenlist = [...tokens];
        const tokenIndex = tokenlist.findIndex(
          (token) => token.id === amount.toString()
        );
        if (from === account && to !== account) {
          if (tokenIndex > -1) {
            tokenlist.splice(tokenIndex, 1);
            setTokens([...tokenlist]);
          }
        }

        if (to && to === account && tokenIndex === -1) {
          const token = await contract.getDataOf(amount.toString());
          const value = await contract.getWorthOf(amount.toString());

          setTokens((prevTokens) =>
            [
              ...prevTokens,
              {
                id: amount.toString(),
                text: token[0],
                imageData: token[1].replace('0x', ''),
                value: formatEther(value).toString(),
              },
            ].sort(sortPixelsFn)
          );
        }
      }
    };

    const handleChangeInCollection = async (from: string, id: BigNumber) => {
      if (contract && account) {
        const tokenIndex = tokens.findIndex(
          (token) => token.id === id.toString()
        );

        if (from && from === account && tokenIndex > -1) {
          const token = await contract.getDataOf(id.toString());
          const value = await contract.getWorthOf(id.toString());

          const tokenlist = [...tokens];
          tokenlist[tokenIndex] = {
            ...tokenlist[tokenIndex],
            text: token[0],
            imageData: token[1].replace('0x', ''),
            value: formatEther(value).toString(),
          };

          setTokens([...tokenlist]);
        }
      }
    };

    if (contract) {
      contract.once('Transfer', handleEditCollection);
      contract.on('ImageChange', handleChangeInCollection);
      contract.on('TextChange', handleChangeInCollection);
      contract.on('PriceChange', handleChangeInCollection);
    }

    return () => {
      if (contract) {
        contract.removeListener('Transfer', handleEditCollection);
        contract.removeListener('ImageChange', handleChangeInCollection);
        contract.removeListener('TextChange', handleChangeInCollection);
        contract.removeListener('PriceChange', handleChangeInCollection);
      }
    };
  }, [contract, account, tokens]);

  return { data: tokens, isLoading, isFetched, error };
};

export const useGetTokenData = (tokenId: string) => {
  const [token, setToken] = useState<Token | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isFetched, setFetched] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const contract = useNFTPixelCanvasContract();

  useEffect(() => {
    const getToken = async () => {
      try {
        if (tokenId && contract) {
          setLoading(true);
          const tokenData = await contract.getDataOf(tokenId);
          const value = await contract.getWorthOf(tokenId);

          let owner = null;
          if (!value.isZero()) {
            owner = await contract.ownerOf(tokenId);
          }

          if (tokenData && value && owner) {
            setToken({
              id: tokenId,
              text: tokenData[0],
              imageData: tokenData[1].replace('0x', ''),
              value: formatEther(value).toString(),
              owner,
            });
          }

          setError(null);
          setLoading(false);
          setFetched(true);
        }
      } catch (e) {
        setToken(null);
        setError(e);
        setLoading(false);
        setFetched(true);
      }
    };

    getToken();
  }, [contract, tokenId]);

  useEffect(() => {
    const handleTransferToken = async (
      from: string,
      to: string,
      amount: BigNumber
    ) => {
      try {
        if (tokenId && tokenId === amount.toString() && contract) {
          setLoading(true);
          const id = amount.toString();
          const tokenData = await contract.getDataOf(id);
          const value = await contract.getWorthOf(id);
          if (tokenData && value) {
            setToken({
              id: amount.toString(),
              text: tokenData[0],
              imageData: tokenData[1].replace('0x', ''),
              value: formatEther(value).toString(),
              owner: to,
            });
          }

          setError(null);
        }
      } catch (e) {
        setToken(null);
        setError(e);
      }
    };

    const handleTokenChange = async (from: string, id: BigNumber) => {
      try {
        if (tokenId && tokenId === id.toString() && contract) {
          const index = id.toString();
          const tokenData = await contract.getDataOf(index);
          const value = await contract.getWorthOf(index);
          if (tokenData && value) {
            setToken((prevToken) =>
              prevToken
                ? {
                    ...prevToken,
                    text: tokenData[0],
                    imageData: tokenData[1].replace('0x', ''),
                    value: formatEther(value).toString(),
                  }
                : null
            );
          }

          setError(null);
        }
      } catch (e) {
        setToken(null);
        setError(e);
      }
    };

    if (contract) {
      contract.on('Transfer', handleTransferToken);
      contract.on('ImageChange', handleTokenChange);
      contract.on('TextChange', handleTokenChange);
      contract.on('PriceChange', handleTokenChange);
    }

    return () => {
      if (contract) {
        contract.removeListener('Transfer', handleTransferToken);
        contract.removeListener('ImageChange', handleTokenChange);
        contract.removeListener('TextChange', handleTokenChange);
        contract.removeListener('PriceChange', handleTokenChange);
      }
    };
  }, [contract, tokenId]);

  return { data: token, isLoading, error, isFetched };
};

export const useTokenBuyoutListener = () => {
  const { account } = useWeb3React();
  const contract = useNFTPixelCanvasContract();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const handleNotification = async (
      from: string,
      to: string,
      amount: BigNumber
    ) => {
      if (contract) {
        if (from === account && to !== account) {
          const value = await contract.getWorthOf(amount.toString());
          enqueueSnackbar(
            `Someone just took pixel ${amount.toString()} from you for ${formatEther(
              value
            ).toString()} ETH.`,
            { variant: 'warning' }
          );
        }
      }
    };

    if (contract) {
      contract.once('Transfer', handleNotification);
    }

    return () => {
      if (contract) {
        contract.removeListener('Transfer', handleNotification);
      }
    };
  }, [account, contract, enqueueSnackbar]);
};
