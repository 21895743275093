import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { useWeb3React } from '@web3-react/core';
import { useGetTokenData } from 'src/hooks/pixelCanvas';
import { useNFTPixelCanvasContract } from 'src/hooks/useContract';
import { CircularLoader } from 'src/components/CircularLoader';
import { Mint } from 'src/components/Mint';
import { Buyout } from 'src/components/Buyout';
import { UpdatePixel } from 'src/components/UpdatePixel';

interface TokenParam {
  tokenId: string;
}

export const TokenPage: React.FunctionComponent = () => {
  const { tokenId } = useParams<TokenParam>();

  const { account, active } = useWeb3React();
  const nftPixelCanvas = useNFTPixelCanvasContract();
  const { data: token, isLoading, isFetched } = useGetTokenData(tokenId);

  if (isLoading && !isFetched) {
    return (
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <CircularLoader />
        </Grid>
      </Grid>
    );
  }

  if (token) {
    if (token.owner === account) {
      return <UpdatePixel token={token} contract={nftPixelCanvas} />;
    }

    return <Buyout token={token} account={account} contract={nftPixelCanvas} />;
  }

  if (!isLoading && isFetched && token === null) {
    if (account && active) {
      return <Mint pixelId={tokenId} />;
    }
    if (!account) {
      return (
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Typography>Connect your wallet to mint new pixels.</Typography>
          </Grid>
        </Grid>
      );
    }
  }

  return null;
};
