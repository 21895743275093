import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { ReactComponent as EthSymbol } from 'src/img/ethereum.svg';

interface EthereumSymbolProps {
  width?: number;
  height?: number;
  svgProps?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}

export const EthereumSymbol: React.FunctionComponent<EthereumSymbolProps> = ({
  width = 24,
  height = 24,
  svgProps = {},
}) => {
  return (
    <Tooltip
      title={
        <Typography variant="body2" sx={{ fontWeight: 400 }}>
          ETH
        </Typography>
      }
      placement="top"
      arrow
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}
      >
        <EthSymbol
          filter="brightness(3)"
          width={width}
          height={height}
          {...svgProps}
        />
      </Box>
    </Tooltip>
  );
};
