import { CssBaseline } from '@mui/material';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import { pink, blue, grey } from '@mui/material/colors';
import React from 'react';

let theme = createTheme({
  palette: {
    mode: 'dark',
    primary: pink,
    secondary: {
      light: blue[50],
      main: blue[200],
      dark: blue[400],
    },
  },
  typography: {
    h3: {
      color: pink[500],
      fontWeight: 500,
      textShadow: `2px 3px 6px ${grey[900]}`,
    },
    h4: {
      textShadow: `2px 3px 5px ${grey[900]}`,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#193549',
          color: 'aliceblue',
        },
        img: {
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: pink[500],
          },
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

export const CustomThemeProvider: React.FunctionComponent = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
