import { Box, Grid, Typography, ToggleButton } from '@mui/material';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@mui/styles';
import ReactGA from 'react-ga';
import { useWeb3React } from '@web3-react/core';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { PixelCollectionContainer } from 'src/components/PixelCollectionContainer';
import { SearchInput } from 'src/components/SearchInput';
import { ROUTE_TO_COLLECTION_PAGE } from 'src/constants/routes';
import { isAddress } from '@ethersproject/address';

interface CollectionParams {
  address: string | undefined;
}

const useStyles = makeStyles({
  searchContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'center',
    margin: '48px 0',
  },
  toggleContainer: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  toggleBox: {
    width: 600,
  },
});

export const CollectionPage = () => {
  const { address } = useParams<CollectionParams>();
  const history = useHistory();
  const [searchAddress, setSearchAddress] = useState<string>('');
  const [accountAddress, setAccountAddress] = useState<string>(address || '');
  const [error, setError] = useState('');

  const { account } = useWeb3React();

  const classes = useStyles();

  const onChange = useCallback((event) => {
    setSearchAddress(event.target.value);
  }, []);

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      if (searchAddress) {
        if (error) {
          setError('');
        }
        ReactGA.event({
          category: 'Collection',
          action: 'Search address',
          label: searchAddress,
        });

        if (searchAddress !== accountAddress) {
          setAccountAddress(searchAddress);
        }

        if (isAddress(searchAddress)) {
          history.push(`${ROUTE_TO_COLLECTION_PAGE}/${searchAddress}`);
        } else {
          setError('The search term is not a valid address.');
        }
      }
    },
    [history, searchAddress, accountAddress, error]
  );

  return (
    <Grid container sx={{ my: 4 }}>
      <Helmet titleTemplate="NFT Pixel Canvas | %s">
        <title>Collection</title>
      </Helmet>
      <Grid item xs={12} sx={{ mb: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Pixel Box Collection
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            mb: 6,
            display: 'flex',
            flexFlow: 'wrap',
            justifyContent: 'center',
          }}
        >
          <SearchInput
            searchValue={searchAddress}
            onChange={onChange}
            onClick={onClick}
          />
          {account && (
            <div className={classes.toggleContainer}>
              <div className={classes.toggleBox}>
                <ToggleButton
                  size="small"
                  color="warning"
                  value="check"
                  disableRipple
                  selected={account === accountAddress}
                  onClick={() => {
                    if (account) {
                      history.push(`${ROUTE_TO_COLLECTION_PAGE}/${account}`);
                      setError('');
                      setSearchAddress('');
                      setAccountAddress(account);
                    }
                  }}
                >
                  My Collection
                </ToggleButton>
              </div>
            </div>
          )}
        </Box>
        <PixelCollectionContainer account={accountAddress} error={error} />
      </Grid>
    </Grid>
  );
};
