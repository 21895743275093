import { Box, Grid } from '@mui/material';
import Konva from 'konva';
import ReactGA from 'react-ga';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import { useNFTPixelCanvasContract } from 'src/hooks/useContract';

import { CircularLoader } from '../CircularLoader';
import { PixelDialog } from './components/PixelDialog';
import { BigNumber } from '@ethersproject/bignumber';
import { formatEther } from '@ethersproject/units';
import { Token } from 'src/types';
import { drawKonvaCanvas, drawKonvaPixel } from 'src/utils/pixels';
import { useWeb3React } from '@web3-react/core';
import { ChainStateData, useGetPixelsImageData } from 'src/hooks/api';

export const DrawBillboard = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<Token | null>(null);
  const [canvasHasLoaded, setCanvasHasLoaded] = useState(false);
  const { account, active } = useWeb3React();

  const { mutateAsync } = useGetPixelsImageData();

  const stage = useRef<Konva.Stage>();
  const layer = useRef<Konva.Layer>();

  const contract = useNFTPixelCanvasContract();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handlePixelClick = async (x: number, y: number) => {
    if (contract) {
      try {
        const index = 128 * y + x;
        const token = await contract.getDataOf(index + 1);
        const value = await contract.getWorthOf(index + 1);

        let owner = null;

        if (token && !value.isZero()) {
          owner = await contract.ownerOf(index + 1);
        }
        setSelected({
          id: (index + 1).toString(),
          text: token[0],
          imageData: token[1].replace('0x', ''),
          value: formatEther(value).toString(),
          owner: owner || '',
        });

        ReactGA.event({
          category: 'Billboard',
          action: 'Click Pixel',
          label: `${index + 1}`,
        });

        handleClickOpen();
      } catch {
        setSelected(null);
      }
    }
  };

  useEffect(() => {
    if (contract) {
      (async () => {
        // setCanvasHasLoaded(false);
        if (!stage.current) {
          stage.current = new Konva.Stage({
            container: 'container',
            width: 1280,
            height: 720,
            pixelRatio: 1,
          });
        }

        layer.current = new Konva.Layer({});
        const length = 128 * 9;

        if (active && account) {
          for (let i = 1; i <= 9216; i = i + length) {
            const data = await contract.getData(i, length);
            drawKonvaCanvas(data, i, length, layer.current, handlePixelClick);
          }
        } else {
          const data: ChainStateData = await mutateAsync();
          const pbData: [string[], string[], BigNumber[]] = [
            data.text,
            data.image,
            data.price,
          ];
          drawKonvaCanvas(pbData, 1, 9216, layer.current, handlePixelClick);
        }

        layer.current.on('mouseenter', (e) => {
          const stage = e.target.getStage();
          if (stage) {
            const container = stage.container();
            if (container) {
              container.style.cursor = 'pointer';
            }
          }
        });
        layer.current.on('mouseleave', (e) => {
          const stage = e.target.getStage();
          if (stage) {
            const container = stage.container();
            if (container) {
              container.style.cursor = 'default';
            }
          }
        });
        stage.current.add(layer.current);
        setCanvasHasLoaded(true);
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  useEffect(() => {
    const handleTokenChange = async (from: string, id: BigNumber) => {
      if (contract && layer.current && stage.current) {
        const idStr = id.toString();
        const token = await contract.getDataOf(idStr);
        const value = await contract.getWorthOf(idStr);
        const pbData = {
          id: idStr,
          imageData: token[1],
          text: token[0],
          value: formatEther(value).toString(),
        };
        drawKonvaPixel(pbData, idStr, layer.current, handlePixelClick);
        stage.current.add(layer.current);
      }
    };

    if (contract && active && account) {
      contract.on('ImageChange', handleTokenChange);
    }

    return () => {
      if (contract && active && account) {
        contract.removeListener('ImageChange', handleTokenChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  return (
    <Grid container sx={{ marginBottom: 4 }}>
      {!canvasHasLoaded && (
        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularLoader />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}
      >
        <SimpleBar
          autoHide={false}
          style={{
            width: '100%',
            maxWidth: 1280,
            height: 733,
            color: 'pink',
            display: !canvasHasLoaded ? 'none' : 'block',
          }}
        >
          <Box id="container" sx={{ width: 1280, height: 720 }}></Box>
        </SimpleBar>
      </Grid>
      {selected && (
        <PixelDialog token={selected} handleClose={handleClose} open={open} />
      )}
    </Grid>
  );
};
