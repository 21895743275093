import axios from 'axios';
import { API_ENDPOINT } from 'src/constants/misc';

const config = {
  baseURL: API_ENDPOINT,
  mode: 'cors',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosClient = axios.create(config);
export { axiosClient };
