import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React, Web3ReactProvider } from '@web3-react/core';
import React, { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { Helmet } from 'react-helmet';
import { SnackbarProvider } from 'notistack';
import { PopupNotifications } from './components/PopupNotifications';
import { CustomThemeProvider } from './containers/CustomThemeProvider';
import { RoutingLayout } from './containers/RoutingLayout';
import { useEagerConnect, useInactiveListener } from './hooks/web3';
import getLibrary from './utils/getLibrary';
import { Web3ReactManager } from './components/Web3ReactManager';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleAnalyticsReporter } from './components/analytics/GoogleAnalyticsReporter';
import { ErrorBoundaryMessage } from './components/ErrorBoundaryMessage';

const AppWrapper: React.FunctionComponent = ({ children }) => {
  const context = useWeb3React<Web3Provider>();
  const { connector } = context;
  const [activatingConnector, setActivatingConnector] = React.useState<any>();

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  return <>{children}</>;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});

function App() {
  return (
    <>
      <Helmet defaultTitle="NFT Pixel Canvas">
        <title>NFT Pixel Canvas</title>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <CustomThemeProvider>
          <Web3ReactProvider getLibrary={getLibrary}>
            <ErrorBoundary
              fallback={({ error, resetError }) => (
                <ErrorBoundaryMessage error={error} resetError={resetError} />
              )}
              showDialog
            >
              <Web3ReactManager>
                <AppWrapper>
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <PopupNotifications />
                    <BrowserRouter>
                      <Route component={GoogleAnalyticsReporter} />
                      <RoutingLayout />
                    </BrowserRouter>
                  </SnackbarProvider>
                </AppWrapper>
              </Web3ReactManager>
            </ErrorBoundary>
          </Web3ReactProvider>
        </CustomThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
