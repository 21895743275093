import { parseEther } from '@ethersproject/units';
import { Grid, Button, TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import React, { useMemo, useState } from 'react';
import { NFTPixelCanvas } from 'src/abis/types';
import { Token } from 'src/types';
import { calculateBuyoutPrice } from 'src/utils/calc';
import { SinglePixelDisplay } from '../SinglePixelDisplay';

interface BuyoutProps {
  token: Token;
  account: string | null | undefined;
  contract: NFTPixelCanvas | null;
}

export const Buyout: React.FunctionComponent<BuyoutProps> = ({
  token,
  account,
  contract,
}) => {
  const minBuyoutValue = useMemo(
    () => calculateBuyoutPrice(token.value),
    [token.value]
  );
  const [error, setError] = useState('');
  const [value, setValue] = useState(minBuyoutValue);

  const buyoutHandler = async () => {
    try {
      if (token && contract) {
        ReactGA.event({
          category: 'Pixel Box',
          action: 'Token page Buyout',
          label: token.id,
        });
        await contract.buyout(token.id, {
          // original value + 20% buyout fee
          value: parseEther(value.toString()),
        });
        setError('');
      }
    } catch (e: any) {
      if (e && typeof e === 'object' && e.code !== 4001) {
        setError('There has been an error during pixel buyout.');
      }
    }
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      event.target.value === '' ? minBuyoutValue : Number(event.target.value)
    );
  };

  const handleBlur = () => {
    if (value < minBuyoutValue) {
      setValue(minBuyoutValue);
    }
  };

  return (
    <Grid container sx={{ mt: 4 }}>
      <Helmet titleTemplate="NFT Pixel Canvas | %s">
        <title>Buyout</title>
        <meta name="description" content="NFT Pixel Canvas Pixel Box buyout" />
      </Helmet>
      <Grid item xs={12}>
        <SinglePixelDisplay token={token} />
      </Grid>
      {account && (
        <>
          <Grid item xs={12} sx={{ mb: 3, ml: 3 }}>
            <Typography
              variant="body2"
              component="div"
              sx={{ color: (theme) => theme.palette.text.secondary }}
              gutterBottom
            >
              Buyout price (+20% on value):
            </Typography>
            <TextField
              value={value}
              size="small"
              onChange={handleValueChange}
              onBlur={handleBlur}
              type="number"
              inputProps={{
                step: 0.01,
                min: minBuyoutValue,
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ ml: 3 }}>
            <Button color="primary" variant="contained" onClick={buyoutHandler}>
              Buyout
            </Button>
          </Grid>
        </>
      )}
      {error && (
        <Grid item xs={12} sx={{ marginBottom: 3 }}>
          <Typography
            sx={{ color: (theme) => theme.palette.error.main }}
            component="div"
          >
            {error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
