import { makeStyles } from '@mui/styles';
import React from 'react';
import { hexColorRegex } from 'src/utils/hex';

const useStyles = makeStyles({
  pixelBoxPixel: {
    display: 'inline-block',
    height: 22.5,
    width: 22.5,
    outline: '1px solid black',

    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
});

interface ColorBoxProps {
  color: string;
  onClick: (event: React.MouseEvent) => void;
  onTouch: () => void;
}

export const ColorBox: React.FC<ColorBoxProps> = ({
  color,
  onClick,
  onTouch,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.pixelBoxPixel}
      style={{
        backgroundColor: hexColorRegex.test(color) ? color : 'white',
      }}
      onMouseDown={onClick}
      onTouchStart={onTouch}
      onMouseOver={onClick}
    />
  );
};
