import { ClearOutlined } from '@mui/icons-material';
import {
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import React, { useCallback, useEffect, useState } from 'react';
import { NFTPixelCanvas } from 'src/abis/types';
import { Token } from 'src/types';
import { flipBytesForArray, regexSplitPixels } from 'src/utils/hex';
import { CustomizablePixelBox } from '../CustomizablePixelBox';

interface UpdatePixelProps {
  token: Token;
  contract: NFTPixelCanvas | null;
}

export const UpdatePixel: React.FunctionComponent<UpdatePixelProps> = ({
  contract,
  token,
}) => {
  const [tokenInitData, setInitTokenData] = useState<string[]>([]);
  const [tokenData, setTokenData] = useState<string[]>([]);
  const [text, setText] = useState('');
  const [initText, setInitText] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (token) {
      const initData = token.imageData.match(regexSplitPixels);
      if (initData) {
        const flippedInitData = flipBytesForArray(initData);

        setInitTokenData([...flippedInitData]);
        setTokenData([...flippedInitData]);
        setInitText(token.text);
        setText(token.text);
      }
    }
  }, [token]);

  const resetChanges = useCallback(() => {
    setTokenData([...tokenInitData]);
  }, [tokenInitData]);

  const textNotUpdated = text === initText;
  const imageDataNotUpdated =
    JSON.stringify(tokenData) === JSON.stringify(tokenInitData);

  const pixelBoxNotUpdated = textNotUpdated && imageDataNotUpdated;

  const handleChangeSubmit = async () => {
    if (contract) {
      try {
        if (!textNotUpdated && !imageDataNotUpdated) {
          // update image data and text
          ReactGA.event({
            category: 'Pixel Box',
            action: 'All data change',
            label: token.id,
          });
          await contract.setDataOf(
            token.id,
            text,
            `0x${flipBytesForArray(tokenData).join('')}`
          );
        } else if (!imageDataNotUpdated) {
          // update image data only
          ReactGA.event({
            category: 'Pixel Box',
            action: 'Image change',
            label: token.id,
          });
          await contract.setImageDataOf(
            token.id,
            `0x${flipBytesForArray(tokenData).join('')}`
          );
        } else if (!textNotUpdated) {
          // update text only
          ReactGA.event({
            category: 'Pixel Box',
            action: 'Text change',
            label: token.id,
          });
          await contract.setTextDataOf(token.id, text);
        }
      } catch (e: any) {
        if (e && typeof e === 'object' && e.code !== 4001) {
          setError(
            'There has been an error while trying to update your pixel.'
          );
        }
      }
    }
  };

  return (
    <Grid container sx={{ mt: 4 }}>
      <Helmet titleTemplate="NFT Pixel Canvas | %s">
        <title>Update Pixel</title>
        <meta name="description" content="NFT Pixel Canvas Pixel Box update" />
      </Helmet>
      <Grid item xs={12}>
        <CustomizablePixelBox
          setTokenData={setTokenData}
          pixelColors={tokenData}
          resetChanges={resetChanges}
          pixelId={token.id}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl sx={{ mb: 3 }} variant="outlined" size="small">
          <InputLabel htmlFor="standard-adornment-password">
            Pixel Box text
          </InputLabel>
          <OutlinedInput
            id="text"
            label="Pixel Box text"
            size="small"
            value={text}
            onChange={(event: any) => setText(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="reset text change"
                  onClick={() => setText(initText)}
                  edge="end"
                  size="small"
                >
                  <ClearOutlined fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangeSubmit}
          disabled={pixelBoxNotUpdated}
        >
          Update Pixel Box
        </Button>
      </Grid>
      {error && (
        <Grid item xs={12} sx={{ my: 3 }}>
          <Typography
            sx={{ color: (theme) => theme.palette.error.main }}
            component="div"
          >
            {error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
