import { BigNumber } from '@ethersproject/bignumber';
import {
  hexColorRegex,
  regexSplitPixels,
  regexSplitPixelsBytes,
} from 'src/utils/hex';
import { Token } from 'src/types';
import Konva from 'konva';
import { formatEther } from '@ethersproject/units';

const view = {
  center: {
    x: 0,
    y: 0,
  },
  zoom: 1,
};
const pixelBoxSize = 10;

// sort by value DESC first, if equal then by token id ASC
export const sortPixelsFn = (tokenA: Token, tokenB: Token) => {
  if (parseFloat(tokenA.value) > parseFloat(tokenB.value)) {
    return -1;
  }
  if (tokenA.value === tokenB.value) {
    if (tokenA.id < tokenB.id) {
      return -1;
    }
    return 1;
  }
  return 1;
};

export const drawCanvas = (
  canvasCtx: CanvasRenderingContext2D,
  imageData: string[],
  i = 0,
  length = 72
) => {
  let index = 0;
  for (
    let y = Math.floor(i / 128);
    y < Math.floor(i / 128) + Math.ceil(length / 128);
    y++
  ) {
    for (let x = 0; x < 128; x++) {
      index++;

      const pbData = imageData[index]
        ? imageData[index].replace('0x', '')
        : null;
      if (pbData) {
        const splitPBData = pbData.match(regexSplitPixels);

        if (splitPBData) {
          for (let i = 0; i < 100; i++) {
            const xx = (i % 10) * 1;
            const yy = parseInt(`${i / 10}`) * 1;
            const bytes = splitPBData[i].match(regexSplitPixelsBytes);
            if (bytes) {
              canvasCtx.fillStyle = `#${bytes[2]}${bytes[1]}${bytes[0]}`;
            } else {
              canvasCtx.fillStyle = `#F1F1F1`;
            }
            canvasCtx.fillRect(
              x * pixelBoxSize * view.zoom +
                xx * 1 * view.zoom -
                view.center.x * view.zoom,
              y * pixelBoxSize * view.zoom +
                yy * 1 * view.zoom -
                view.center.y * view.zoom,
              1 * view.zoom,
              1 * view.zoom
            );
          }
        } else {
          canvasCtx.fillStyle = `#F1F1F1`;
          canvasCtx.fillRect(
            x * pixelBoxSize * view.zoom - view.center.x * view.zoom,
            y * pixelBoxSize * view.zoom - view.center.y * view.zoom,
            10 * view.zoom,
            10 * view.zoom
          );
        }
      } else {
        canvasCtx.fillStyle = `#F1F1F1`;
        canvasCtx.fillRect(
          x * pixelBoxSize * view.zoom - view.center.x * view.zoom,
          y * pixelBoxSize * view.zoom - view.center.y * view.zoom,
          10 * view.zoom,
          10 * view.zoom
        );
      }
    }
  }
};

export const drawReservationCanvas = (
  canvasCtx: CanvasRenderingContext2D,
  imageData: boolean[]
) => {
  let index = 0;
  for (let x = 0; x < 128; x++) {
    for (let y = 0; y < 72; y++) {
      index++;

      canvasCtx.fillStyle = '#666666';
      canvasCtx.fillRect(
        x * pixelBoxSize * view.zoom - view.center.x * view.zoom,
        y * pixelBoxSize * view.zoom - view.center.y * view.zoom,
        10 * view.zoom,
        10 * view.zoom
      );

      canvasCtx.fillStyle = imageData[index] ? '#ff0000' : '#ffffff';
      canvasCtx.fillRect(
        1 + x * pixelBoxSize * view.zoom - view.center.x * view.zoom,
        1 + y * pixelBoxSize * view.zoom - view.center.y * view.zoom,
        -2 + 10 * view.zoom,
        -2 + 10 * view.zoom
      );
    }
  }
};

const drawSinglePixel = (
  pbData: Token | null,
  x: number,
  y: number,
  layer: Konva.Layer,
  handlePixelClick: (x: number, y: number) => void
) => {
  if (pbData?.imageData) {
    const splitPBData = pbData.imageData.match(regexSplitPixels);

    if (splitPBData) {
      for (let i = 0; i < 100; i++) {
        const xx = (i % 10) * 1;
        const yy = parseInt(`${i / 10}`) * 1;
        const bytes = splitPBData[i].match(regexSplitPixelsBytes);
        const hexColor = bytes
          ? `#${bytes[2]}${bytes[1]}${bytes[0]}`
          : `#F1F1F1`;

        const rect = new Konva.Rect({
          x:
            x * pixelBoxSize * view.zoom +
            xx * 1 * view.zoom -
            view.center.x * view.zoom,
          y:
            y * pixelBoxSize * view.zoom +
            yy * 1 * view.zoom -
            view.center.y * view.zoom,
          width: 1,
          height: 1,
          fill: hexColorRegex.test(hexColor) ? hexColor : `#F1F1F1`,
          preventDefault: false,
        });
        rect.on('click tap', () => handlePixelClick(x, y));
        rect.on('mouseenter', (e) => {
          const stage = e.target.getStage();
          if (stage) {
            const container = stage.container();
            if (container) {
              container.title = `Pixel Box #${pbData.id}\n${
                pbData.text
              }\n\n${Array(30 + 1).join('-')}\nValue: ${pbData.value} ETH`;
            }
          }
        });
        rect.on('mouseleave', (e) => {
          const stage = e.target.getStage();
          if (stage) {
            const container = stage.container();
            if (container) {
              container.title = '';
            }
          }
        });
        layer.add(rect);
      }
    } else {
      const rect = new Konva.Rect({
        x: x * pixelBoxSize * view.zoom - view.center.x * view.zoom,
        y: y * pixelBoxSize * view.zoom - view.center.y * view.zoom,
        width: 10,
        height: 10,
        fill: `#F1F1F1`,
        stroke: 'black',
        strokeWidth: 0.2,
        preventDefault: false,
      });
      rect.on('click tap', () => handlePixelClick(x, y));
      layer.add(rect);
    }
  } else {
    const rect = new Konva.Rect({
      x: x * pixelBoxSize * view.zoom - view.center.x * view.zoom,
      y: y * pixelBoxSize * view.zoom - view.center.y * view.zoom,
      width: 10,
      height: 10,
      fill: `#F1F1F1`,
      stroke: 'black',
      strokeWidth: 0.2,
      preventDefault: false,
    });
    rect.on('click tap', () => handlePixelClick(x, y));
    layer.add(rect);
  }
};

export const drawKonvaPixel = (
  token: Token | null,
  tokenId: string,
  layer: Konva.Layer,
  handlePixelClick: (x: number, y: number) => void
) => {
  if (token) {
    const pbData = {
      ...token,
      imageData: token ? token.imageData.replace('0x', '') : '',
    };
    const index = parseInt(tokenId, 10) - 1;
    const x = index % 128;
    const y = Math.floor(index / 128);
    drawSinglePixel(pbData, x, y, layer, handlePixelClick);
  }
};

export const drawKonvaCanvas = (
  data: [string[], string[], BigNumber[]],
  iteration: number,
  length: number,
  layer: Konva.Layer,
  handlePixelClick: (x: number, y: number) => void
) => {
  let index = 0;
  for (
    let y = Math.floor(iteration / 128);
    y < Math.floor(iteration / 128) + Math.ceil(length / 128);
    y++
  ) {
    for (let x = 0; x < 128; x++) {
      const pbData = {
        id: `${index + 1}`,
        text: data[0][index],
        imageData: data[1][index] ? data[1][index].replace('0x', '') : '',
        value: formatEther(data[2][index]).toString(),
      };
      drawSinglePixel(pbData, x, y, layer, handlePixelClick);
      index++;
    }
  }
};
