import { Helmet } from 'react-helmet';
import React from 'react';
import { About } from 'src/components/About';
import { CountdownToReservation } from 'src/components/CountdownToReservation';
import { TimelineComponent } from 'src/components/Timeline';
import { DemoPixelBoxes } from 'src/components/DemoPixelBoxes';

export const HomePage = () => {
  return (
    <>
      <Helmet titleTemplate="NFT Pixel Canvas | %s">
        <title>Home</title>
      </Helmet>
      <About />
      <CountdownToReservation />
      <DemoPixelBoxes />
      <TimelineComponent />
    </>
  );
};
