import { Box, Grid, Typography } from '@mui/material';
import { SinglePixelDisplay } from 'src/components/SinglePixelDisplay';

const data = [
  {
    id: '1',
    text: 'Follow us on Twitter https://twitter.com/NFTPixelCanvas',
    imageData:
      '578166bdcb6c99ac92347d1d7eea5d90a3706bedbc2b1e0709a5386251b8fa2d04cab008fa712d551e0783886ef9dbe4739004225132b2dc57004af16b029b9e35e0ab1870fea32c3f14f1d03f597dc8ece23246ae9dd493fa0db8556160c2239140d324ba752e26cc7346928c8413d3880a97bda104f12032e7b21bf8d7e556530bdb58b5d9e7a6f8644cb6cfd5367cb203a9170c2bcdcc432238a96c13e1f6038ac374e23ddcff1152bdaefa88ed5acd60eb49714a47edb3d41dd9f370c0f31d4d663608d7a1cebc474d0189e771c1b33d15bfdca40c65d40972bc01b57c7e3c6580b489686563f0efbbb4d8b2ea78d097d1172e1a3d62f10a54ea81f0780759a942ee6c7c1109edf98ca129eebba83dd8c2584916a618eed28513e86303322abe8148c4f033a0f7c65227',
    value: '',
  },
  {
    id: '2',
    text: ':)',
    imageData:
      'f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f73131f73131f73131f4f731f4f731f73131f73131f73131f4f731f4f731f73131f4f731f73131f4f731f4f731f73131f4f731f73131f4f731f4f731f73131f73131f73131f4f731f4f731f73131f73131f73131f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f73131f73131f4f731f4f731f4f731f4f731f4f731f73131f73131f4f731f4f731f4f731f4f731f73131f73131f4f731f4f731f4f731f73131f4f731f4f731f4f731f4f731f73131f4f731f4f731f4f731f4f731f73131f73131f73131f73131f73131f73131f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731f4f731',
    value: '',
  },
  {
    id: '3',
    text: 'the void',
    imageData:
      'd80da9121112121112121112d80da9121112121112121112121112d80da9d80da9d80da9121112121112d80da9121112d80da9d80da9d80da9d80da9121112d80da9d80da9d80da9d80da9d80da9d80da9121112121112121112121112121112d80da9d80da9d80da9d80da9d80da9121112121112121112d80da9d80da9d80da9d80da9d80da9d80da9d80da9d80da9d80da9d80da9121112d80da9d80da9d80da9d80da9d80da9d80da9121112121112121112121112121112d80da9d80da9d80da9d80da9d80da9d80da9121112121112121112d80da9d80da9121112d80da9121112121112d80da9121112121112d80da9d80da9121112121112d80da9121112121112d80da9d80da9121112d80da9121112121112121112d80da9121112121112121112d80da9d80da9',
    value: '',
  },
  {
    id: '4',
    text: 'my artistic masterpiece',
    imageData:
      'ffffffffffffffffffffffffffffffffffff12e9fd12e9fd12e9fd12e9fdffffff0fea180fea180fea180fea180fea18ffffffffffff12e9fd12e9fd0fea180fea180fea180fea180fea180fea18ffffff12e9fdffffff12e9fd0fea180fea180fea180fea180fea180fea180fea18ffffffffffff12e9fd0fea180fea180d293b0d293b0d293b0fea180fea18ffffffffffffffffffffffff0fea180d293b0d293b0d293b0fea18ffffffffffffffffffffffffffffffffffff0d293b0d293b0d293bffffffffffffffffffffffffffffffffffffffffff0d293b0d293b0d293b14f963ffffffffffffffffffffffff0e2e3f0e2e3f0e2e3f0e2e3f0d293b14f96314f96314f963ea3d0fea3d0f0e2e3f0e2e3f0e2e3f0e2e3f0e2e3f0e2e3f0e2e3f0e2e3fea3d0fea3d0f',
    value: '',
  },
];

export const DemoPixelBoxes = () => {
  return (
    <Grid container sx={{ justifyContent: 'center', mt: { xs: 5, sm: 10 } }}>
      <Grid item xs={12} md={8} lg={5} sx={{ textAlign: 'center', mb: 4 }}>
        <Typography
          variant="h4"
          component="div"
          sx={{
            fontWeight: 500,
          }}
          gutterBottom
        >
          Pixel Boxes
        </Typography>
        <Typography variant="subtitle1">
          You get to design and change the content of your Pixel Boxes as many
          times as you like, so let your imagination run wild.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 4 }}>
        <Box
          sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}
        >
          {data.map((token, index) => (
            <SinglePixelDisplay key={index} token={token} />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
