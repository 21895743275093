import { parseEther } from '@ethersproject/units';
import { Button, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import React, { useCallback, useState } from 'react';
import { useNFTPixelCanvasContract } from 'src/hooks/useContract';
import { flipBytesForArray } from 'src/utils/hex';
import { CustomizablePixelBox } from '../CustomizablePixelBox';
import {
  INITIAL_TOKEN_DATA,
  MINT_VALUE,
  RESERVATION_MINT_END_TIME,
  RESERVATION_MINT_START_TIME,
} from '../../constants/misc';
// import { ContractTransaction } from '@ethersproject/contracts';
import { useWeb3React } from '@web3-react/core';

interface MintProps {
  pixelId?: string;
  preSale?: boolean;
  proof?: string[];
  pixelIndex?: number;
}

export const Mint: React.FunctionComponent<MintProps> = ({
  pixelId,
  preSale = false,
  proof = [],
  pixelIndex,
}) => {
  const minMintValue = Number(MINT_VALUE);
  const [value, setValue] = useState(minMintValue);
  const [tokenData, setTokenData] = useState<string[]>([...INITIAL_TOKEN_DATA]);
  const [fromInitialSeed, setFromInitialSeed] = useState(false);
  const [index, setIndex] = useState<number>(
    pixelId ? parseInt(pixelId, 10) : 1
  );
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const nftPixelCanvas = useNFTPixelCanvasContract();
  const { account } = useWeb3React();

  const handleMint = async () => {
    try {
      if (nftPixelCanvas && account) {
        const flippedData = flipBytesForArray(tokenData);
        const tokenImageData = fromInitialSeed
          ? '0x'
          : `0x${flippedData.join('')}`;
        //let newPixel: ContractTransaction | undefined;
        if (preSale && proof && pixelIndex) {
          const currentTime = Date.now();
          if (
            currentTime >= RESERVATION_MINT_START_TIME &&
            currentTime <= RESERVATION_MINT_END_TIME
          ) {
            ReactGA.event({
              category: 'Pixel Box',
              action: 'Presale Mint',
              label: `${index}`,
            });
            await nftPixelCanvas.preSaleMintPixelBox(
              index,
              account,
              pixelIndex,
              proof,
              tokenImageData,
              text,
              {
                value: parseEther(value.toString()),
              }
            );
            setError('');
          } else {
            setError('Presale minting phase is not active.');
          }
        } else {
          ReactGA.event({
            category: 'Pixel Box',
            action: 'Mint',
            label: `${index}`,
          });
          await nftPixelCanvas.mintPixelBox(index, tokenImageData, text, {
            value: parseEther(value.toString()),
          });
          setError('');
        }

        // if (newPixel) {
        //   setTokenData([...INITIAL_TOKEN_DATA]);
        //   setText('');
        //   setIndex(1);
        // }
      }
    } catch (e: any) {
      if (e && typeof e === 'object' && e.code !== 4001) {
        if (e.message.includes('Not active')) {
          setError('Minting phase is not active.');
        } else if (e.message.includes('NFT already minted.')) {
          setError('The selected Pixel Box is already minted.');
        } else {
          setError('There has been an error while trying to mint your pixel.');
        }
      }
    }
  };

  const handleTextInput = (value: string) => {
    setText(value);
  };

  const handleIndexInput = (value: string) => {
    const idx = value && !isNaN(parseInt(value, 10)) ? parseInt(value, 10) : 1;
    if (idx < 1) {
      setIndex(1);
    } else {
      setIndex(idx);
    }
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      event.target.value === '' ? minMintValue : Number(event.target.value)
    );
  };

  const handleBlur = () => {
    if (value < minMintValue) {
      setValue(minMintValue);
    }
  };

  const resetChanges = useCallback(() => {
    setTokenData([...INITIAL_TOKEN_DATA]);
  }, []);

  return (
    <Grid container sx={{ mt: 4 }}>
      <Helmet titleTemplate="NFT Pixel Canvas | %s">
        <title>Mint</title>
        <meta name="description" content="NFT Pixel Canvas Pixel Box minting" />
      </Helmet>
      <Grid item xs={12} sx={{ justifyContent: 'center', marginBottom: 3 }}>
        <CustomizablePixelBox
          setTokenData={setTokenData}
          pixelColors={tokenData}
          resetChanges={resetChanges}
          pixelId={pixelId}
          setFromInitialSeed={setFromInitialSeed}
          fromInitialSeed={fromInitialSeed}
          mint
        />

        <Grid container sx={{ justifyContent: 'center' }}>
          {!pixelId && (
            <Grid item xs={12} sx={{ marginBottom: 3 }}>
              <FormGroup row>
                <TextField
                  id="index"
                  label="Index"
                  type="number"
                  InputProps={{ inputProps: { min: 1, max: 9216 } }}
                  value={index}
                  onChange={(event: any) =>
                    handleIndexInput(event.target.value)
                  }
                  size="small"
                />
              </FormGroup>
            </Grid>
          )}
          <Grid item xs={12} sx={{ mb: 3 }}>
            <FormGroup row>
              <TextField
                label="Pixel Box text"
                value={text}
                onChange={(event: any) => handleTextInput(event.target.value)}
                size="small"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Typography
              variant="body2"
              component="div"
              sx={{ color: (theme) => theme.palette.text.secondary }}
              gutterBottom
            >
              Mint price (buyout threshold is +20% on mint value):
            </Typography>
            <TextField
              value={value}
              size="small"
              onChange={handleValueChange}
              onBlur={handleBlur}
              type="number"
              inputProps={{
                step: 0.01,
                min: minMintValue,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleMint}>
              Mint
            </Button>
          </Grid>
          {error && (
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Typography
                sx={{ color: (theme) => theme.palette.error.main }}
                component="div"
              >
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
