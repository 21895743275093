import { parseEther } from '@ethersproject/units';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useWeb3React } from '@web3-react/core';
import ReactGA from 'react-ga';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { EthereumSymbol } from 'src/components/EthereumSymbol';
import { TextWithLinks } from 'src/components/TextWithLinks';
import { ROUTE_TO_TOKEN_PAGE } from 'src/constants/routes';
import { useNFTPixelCanvasContract } from 'src/hooks/useContract';
import { generateSingleDataURL } from 'src/nftpixelcanvas-sdk';
import { Token } from 'src/types';
import { calculateBuyoutPrice } from 'src/utils/calc';

interface PixelDialogProps {
  token: Token;
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles({
  pixelContainer: {
    display: 'inline-block',
    alignItems: 'center',
    textAlign: 'center',
    width: 206,
    marginRight: 36,
    marginBottom: 16,
  },
  pixelWrapper: {
    backgroundColor: '#0C1720',
    padding: 28,
    height: 206,
  },
});

export const PixelDialog: React.FunctionComponent<PixelDialogProps> = ({
  token,
  open,
  handleClose,
}) => {
  // original value + 20% buyout fee
  const minBuyoutValue = useMemo(
    () => calculateBuyoutPrice(token.value),
    [token.value]
  );
  const [error, setError] = useState('');
  const [value, setValue] = useState(minBuyoutValue);
  const history = useHistory();
  const classes = useStyles();
  const { account } = useWeb3React();
  const contract = useNFTPixelCanvasContract();

  const imageData = generateSingleDataURL(token.imageData, 15);

  const buyoutHandler = async () => {
    try {
      if (token && contract) {
        ReactGA.event({
          category: 'Pixel Box',
          action: 'Dialog Buyout',
          label: token.id,
        });
        await contract.buyout(token.id, {
          value: parseEther(value.toString()),
        });
        setError('');
        handleClose();
      }
    } catch (e: any) {
      if (e && typeof e === 'object' && e.code !== 4001) {
        setError('There has been an error during pixel buyout.');
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      event.target.value === '' ? minBuyoutValue : Number(event.target.value)
    );
  };

  const handleBlur = () => {
    if (value < minBuyoutValue) {
      setValue(minBuyoutValue);
    }
  };

  const renderImageData = () => (
    <div className={classes.pixelWrapper}>
      <img src={imageData} />
    </div>
  );

  const renderActionButtons = () => {
    if (account) {
      if (!token.owner) {
        return (
          <Button
            variant="contained"
            onClick={() => {
              history.push(`${ROUTE_TO_TOKEN_PAGE}/${token.id}`);
            }}
          >
            Mint
          </Button>
        );
      }
      if (token.owner !== account) {
        return (
          <Button variant="contained" onClick={buyoutHandler}>
            Buyout
          </Button>
        );
      }
      return (
        <Button
          variant="contained"
          onClick={() => {
            history.push(`${ROUTE_TO_TOKEN_PAGE}/${token.id}`);
          }}
        >
          Change
        </Button>
      );
    }

    return (
      <Typography variant="body2" component="div" sx={{ mb: 1 }}>
        Connect wallet for additional actions.
      </Typography>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setError('');
        handleClose();
      }}
      aria-labelledby="responsive-dialog-title"
      fullWidth
    >
      <DialogTitle id="responsive-dialog-title">
        Pixel Box #{token.id}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={() => {
              setError('');
              handleClose();
            }}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ pb: 4 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <div className={classes.pixelContainer}>{renderImageData()}</div>
          {token.owner && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                mb: '16px',
              }}
            >
              <Typography variant="body2" component="div" sx={{ mb: 1 }}>
                <TextWithLinks tokenText={token.text} />
              </Typography>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  Value:
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{ mr: 0.5, fontSize: '1.2rem' }}
                  >
                    {token.value}
                  </Typography>
                  <EthereumSymbol />
                </Box>
              </Box>
              <Box sx={{ minHeight: 65 }}>
                {token.owner && account && token.owner !== account && (
                  <>
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ color: (theme) => theme.palette.text.secondary }}
                      gutterBottom
                    >
                      Buyout price (+20% on value):
                    </Typography>
                    <TextField
                      value={value}
                      size="small"
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      type="number"
                      inputProps={{
                        step: 0.01,
                        min: minBuyoutValue,
                      }}
                    />
                  </>
                )}
              </Box>
            </Box>
          )}
        </Box>
        {error && (
          <Typography
            sx={{ color: (theme) => theme.palette.error.main }}
            component="div"
          >
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3 }}>
        {renderActionButtons()}
      </DialogActions>
    </Dialog>
  );
};
