import { Web3Provider } from '@ethersproject/providers';

export default function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(
    provider,
    typeof provider.chainId === 'number'
      ? provider.chainId
      : typeof provider.chainId === 'string'
      ? parseInt(provider.chainId)
      : 'any'
  );
  if (provider.isMetamask) {
    library.pollingInterval = 15_000;
  } else {
    library.pollingInterval = 60_000;
  }
  return library;
}
