import { Buffer } from 'buffer';

export const COLOR_HEX_REGEX = /^#[0-9A-F]{6}$/i;
export const BOX_SIZE = 10;
export const DEFAULT_PIXEL_SIZE = 10;
export const COLOR_HEX_LENGTH = 6;
export const DEFAULT_CANVAS_WIDTH = 1280;
export const DEFAULT_CANVAS_HEIGHT = 720;

export const BMPHeader = Buffer.from([
  0x42, 0x4d, 0x76, 0x01, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x36, 0x00, 0x00,
  0x00, 0x28, 0x00, 0x00, 0x00, 0x0a, 0x00, 0x00, 0x00, 0xf6, 0xff, 0xff, 0xff,
  0x01, 0x00, 0x18, 0x00, 0x00, 0x00, 0x00, 0x00, 0x40, 0x01, 0x00, 0x00, 0x00,
  0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
  0x00, 0x00,
]);

export const BMPRowEnd = Buffer.from([0x00, 0x00]);
