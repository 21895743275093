import invariant from 'tiny-invariant';
import {
  BOX_SIZE,
  COLOR_HEX_LENGTH,
  COLOR_HEX_REGEX,
  DEFAULT_PIXEL_SIZE,
} from './utils/constants';

const svgToDataURL = (svgString: string): string => {
  const encodedSvg = encodeURIComponent(svgString)
    .replace(/'/g, '%27')
    .replace(/"/g, '%22');

  const header = 'data:image/svg+xml,';
  const dataUrl = header + encodedSvg;

  return dataUrl;
};

const flipBytes = (hexString: string, x: number, y: number) => {
  const hex = hexString.slice(
    y * BOX_SIZE * COLOR_HEX_LENGTH + x * COLOR_HEX_LENGTH,
    y * BOX_SIZE * COLOR_HEX_LENGTH + x * COLOR_HEX_LENGTH + COLOR_HEX_LENGTH
  );
  const hexArray = hex.split('');

  const tmp = hexArray.slice(-2);

  hexArray.splice(-2, 2, ...hexArray.slice(0, 2));
  hexArray.splice(0, 2, ...tmp);

  return hexArray.join('');
};

const buildRectanglesFromHex = (hexString: string, pixelSize: number) => {
  let svg = '';

  for (let y = 0; y < BOX_SIZE; y++) {
    for (let x = 0; x < BOX_SIZE; x++) {
      const finalHex = `#${flipBytes(hexString, x, y)}`;
      const colorHex = COLOR_HEX_REGEX.test(finalHex) ? finalHex : '#000000';
      svg =
        svg +
        `<rect width="${pixelSize}" height="${pixelSize}" x="${
          x * pixelSize
        }" y="${y * pixelSize}" style="fill:${colorHex};" />`;
    }
  }

  return svg;
};

const validHexStringCheck = (hexString: string) => {
  // invariant(hexString.match(HEX_REGEX), 'Input is not hexadecimal string');
  // invariant(hexString.length === 600, 'Image data is not of length 600');
};

export const generateSingleDataURL = (
  data: string,
  pixelSize = DEFAULT_PIXEL_SIZE
): string => {
  // const hexString = data.slice(0, -232);
  // const textDataAsBuffer = data.slice(-232);

  validHexStringCheck(data);

  const dimensions = BOX_SIZE * pixelSize;

  const svgContent = buildRectanglesFromHex(data, pixelSize);

  const svg = `<?xml version="1.0" encoding="utf-8"?><svg width="${dimensions}"
  height="${dimensions}" version="1.1" xmlns="http://www.w3.org/2000/svg">
    ${svgContent}
  </svg>`;

  return svgToDataURL(svg);
};

// export const generateCanvasDataURL = (data: string[]) => {
//   invariant(data.length === 128 * 72, 'Input is not of required length');

//   // const dimensions = BOX_SIZE * PIXEL_SIZE;
//   let svg = `<?xml version="1.0" encoding="utf-8"?><svg width="1280"
//   height="720" version="1.1" xmlns="http://www.w3.org/2000/svg">`;

//   for (let y = 0; y < 72; y++) {
//     for (let x = 0; x < 128; x++) {
//       const hexString = data[y * x + x].slice(0, -232);

//       validHexStringCheck(hexString);

//       svg =
//         svg +
//         `<svg width=${BOX_SIZE} height=${BOX_SIZE} x="${x * BOX_SIZE}" y="${y *
//           BOX_SIZE}" version="1.1" xmlns="http://www.w3.org/2000/svg">`;
//       const singlePixelBox = buildRectanglesFromHex(hexString, 1);
//       svg = svg + singlePixelBox + '</svg>';
//     }
//   }

//   svg = svg + '</svg>';
//   return svgToDataURL(svg);
// };

// export const generateCanvasDataURL = async (
//   data: string[],
//   pixelBoxSize = BOX_SIZE
// ) => {
//   const width = DEFAULT_CANVAS_WIDTH / pixelBoxSize;
//   const height = DEFAULT_CANVAS_HEIGHT / pixelBoxSize;

//   const totalPixelBoxes = width * height;

//   invariant(data.length === totalPixelBoxes, 'Input is not of required length');

//   const canvas = createCanvas(width * pixelBoxSize, height * pixelBoxSize);
//   const ctx = canvas.getContext('2d');

//   const finalState: { [key: string]: any } = {};

//   for (let i = 0; i < totalPixelBoxes; i++) {
//     const pixelBoxIndexNumber = `${i + 1}`.padStart(4, '0');

//     const pixelData = Buffer.from(data[i], 'hex');

//     const rawPixelData = pixelData.slice(0, -116).toString('hex');

//     //const singleColorRegex = new RegExp('/.{1,6}/g');
//     // const rawPixelData2 = rawPixelData
//     //   .match(/.{1,6}/g)!
//     //   .reduce(
//     //     (prevValue, currentValue) =>
//     //       prevValue.concat(...Array(5).fill(currentValue)),
//     //     []
//     //   )
//     //   .join('');

//     const rowRegex = new RegExp(`.{1,${60}}`, 'g');

//     // const rawPixelData3 = rawPixelData2
//     //   .match(rowRegex)!
//     //   .reduce((prevValue, currentValue) => {
//     //     return prevValue.concat(...Array(5).fill(currentValue));
//     //   }, [])
//     //   .join('');

//     const arrayRawPixelData =
//       rawPixelData.match(rowRegex)?.join(BMPRowEnd.toString('hex')) +
//       BMPRowEnd.toString('hex');

//     const imageDataAsBuffer = Buffer.concat([
//       BMPHeader,
//       Buffer.from(arrayRawPixelData, 'hex'),
//     ]);

//     const textDataAsBuffer = Buffer.from(Array(116 + 1).join(' '), 'ascii');

//     const finalDataOnChain = Buffer.concat([
//       pixelData.slice(0, -116),
//       textDataAsBuffer,
//     ]);

//     finalState[pixelBoxIndexNumber] = {
//       index: pixelBoxIndexNumber,
//       data: `${finalDataOnChain.toString('hex')}`,
//     };

//     const x = (i % width) * pixelBoxSize;
//     const y = parseInt(`${i / width}`) * pixelBoxSize;
//     console.log(imageDataAsBuffer);
//     const canvasReadyImage = await loadImage(imageDataAsBuffer);

//     ctx.drawImage(canvasReadyImage, x, y);
//   }

//   return canvas.toDataURL();
// };
