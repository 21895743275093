import { AbstractConnector } from '@web3-react/abstract-connector';
import { Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ReactGA from 'react-ga';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import React, { useEffect, useState } from 'react';
import { isMobile } from '../../utils/userAgent';
import { Option } from './components/Option';
import { SUPPORTED_WALLETS } from 'src/constants/wallet';
import { injected } from 'src/connectors';
import { AccountDetails } from '../AccountDetails';
import usePrevious from 'src/hooks/usePrevious';
import { ModalCloseButton } from '../ModalCloseButton';
import MetamaskIcon from 'src/assets/img/metamask.png';
import { PendingView } from './components/PendingView';

interface WalletModal {
  walletModalOpen: boolean;
  toggleWalletModal: () => void;
}

const WALLET_VIEWS = {
  OPTIONS: 'options',
  ACCOUNT: 'account',
  PENDING: 'pending',
};

export const WalletModal: React.FunctionComponent<WalletModal> = ({
  walletModalOpen,
  toggleWalletModal,
}) => {
  const { account, active, connector, activate, error } = useWeb3React();

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);
  const [pendingWallet, setPendingWallet] = useState<
    AbstractConnector | undefined
  >();
  const [pendingError, setPendingError] = useState<boolean>();

  const previousAccount = usePrevious(account);

  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal();
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen]);

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [walletModalOpen]);

  // close modal when a connection is successful
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (
      walletModalOpen &&
      ((active && !activePrevious) ||
        (connector && connector !== connectorPrevious && !error))
    ) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [
    setWalletView,
    active,
    error,
    connector,
    walletModalOpen,
    activePrevious,
    connectorPrevious,
  ]);

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    let name = '';
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name);
      }
      return true;
    });
    // log selected wallet
    ReactGA.event({
      category: 'Wallet',
      action: 'Connect',
      label: name,
    });
    setPendingWallet(connector); // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING);

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector instanceof WalletConnectConnector) {
      connector.walletConnectProvider = undefined;
    }
    connector &&
      activate(connector, undefined, true)
        .then()
        .catch((error) => {
          if (error instanceof UnsupportedChainIdError) {
            activate(connector); // a little janky...can't use setError because the connector isn't set
          } else {
            setPendingError(true);
          }
        });
  };

  // get wallets user can switch too, depending on device/browser
  const getOptions = () => {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask;
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key];
      // check for mobile options
      if (isMobile) {
        if (!window.web3 && !window.ethereum && option.mobile) {
          return (
            <Option
              onClick={() => {
                option.connector !== connector &&
                  tryActivation(option.connector);
              }}
              id={`connect-${key}`}
              key={key}
              active={option.connector && option.connector === connector}
              color={option.color}
              link={option.href}
              header={option.name}
              subheader={null}
              icon={option.iconURL}
            >
              {option.name}
            </Option>
          );
        }
        return null;
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header="Install Metamask"
                subheader={null}
                link={'https://metamask.io/'}
                icon={MetamaskIcon}
              >
                {option.name}
              </Option>
            );
          } else {
            return null; //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null;
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetamask) {
          return null;
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            id={`connect-${key}`}
            onClick={() => {
              option.connector !== connector && tryActivation(option.connector);
            }}
            key={key}
            active={option.connector === connector}
            color={option.color}
            link={option.href}
            header={option.name}
            subheader={null} //use option.descriptio to bring back multi-line
            icon={option.iconURL}
          >
            {option.name}
          </Option>
        )
      );
    });
  };

  const getModalContent = () => {
    if (error) {
      return (
        <Box>
          <Box>
            {error instanceof UnsupportedChainIdError
              ? 'Wrong Network'
              : 'Error connecting'}
          </Box>
          <Box>
            {error instanceof UnsupportedChainIdError ? (
              <h5>Please connect to the appropriate Ethereum network.</h5>
            ) : (
              ' Error connecting. Try refreshing the page.'
            )}
          </Box>
        </Box>
      );
    }
    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
        />
      );
    }

    return walletView === WALLET_VIEWS.PENDING ? (
      <PendingView
        connector={pendingWallet}
        error={pendingError}
        setPendingError={setPendingError}
        tryActivation={tryActivation}
      />
    ) : (
      getOptions()
    );
  };

  return (
    <Dialog
      open={walletModalOpen}
      onClose={toggleWalletModal}
      aria-labelledby="responsive-dialog-title"
      fullWidth
    >
      <DialogTitle id="responsive-dialog-title" sx={{ pb: 4 }}>
        Connect a wallet
        {toggleWalletModal ? (
          <ModalCloseButton toggleModal={toggleWalletModal} />
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ pb: 4 }}>{getModalContent()}</DialogContent>
    </Dialog>
  );
};
