import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Link,
  Typography,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import React from 'react';
import { green } from '@mui/material/colors';

interface OptionProps {
  link?: string | null;
  clickable?: boolean;
  size?: string | number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  color: string;
  header: React.ReactNode;
  subheader: React.ReactNode | null;
  icon: string;
  active?: boolean;
  id: string;
}

export const Option: React.FunctionComponent<OptionProps> = ({
  link = null,
  clickable = true,
  size = 24,
  onClick = undefined,
  color,
  header,
  subheader = null,
  icon,
  active = false,
  id,
}) => {
  const content = (
    <Card
      id={id}
      sx={{
        marginTop: 2,
        border: '1px solid',
        borderColor: (theme) =>
          active ? theme.palette.primary.main : 'transparent',
        '&:hover': {
          borderColor: (theme) => (clickable ? theme.palette.primary.main : ``),
        },
      }}
    >
      <CardActionArea
        color="secondary"
        disabled={!clickable || active}
        onClick={onClick}
        disableRipple
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 3,
          textTransform: 'none',
        }}
      >
        <CardContent sx={{ p: 0, justifyContent: 'center', height: '100%' }}>
          <Typography
            component="div"
            variant="body1"
            color={color}
            sx={{
              fontWeight: 500,
              fontSize: '1.1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {active ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 1,
                }}
              >
                <CircleIcon
                  sx={{
                    height: 8,
                    width: 8,
                    fill: green[700],
                  }}
                />
              </Box>
            ) : (
              ''
            )}
            {header}
          </Typography>
          {subheader && <Typography>{subheader}</Typography>}
        </CardContent>
        <Box
          sx={{
            alignItems: { xs: 'center', md: 'flex-end' },
            justifyContent: 'center',
          }}
        >
          <CardMedia
            height={size}
            width={size}
            component="img"
            src={icon}
            alt="Icon"
            sx={{ boxShadow: 'none' }}
          />
        </Box>
      </CardActionArea>
    </Card>
  );

  if (link) {
    return (
      <Link sx={{ textDecoration: 'none' }} href={link} target="_blank">
        {content}
      </Link>
    );
  }

  return content;
};
