import { Contract } from '@ethersproject/contracts';
import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';
import { NFTPixelCanvas } from 'src/abis/types';
import { NFT_PIXEL_CANVAS_CONTRACT } from 'src/constants/contractAddress';
import NFT_PIXEL_CANVAS_ABI from '../abis/NFTPixelCanvas.json';
import { getContract } from '../utils';

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useWeb3React();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      // console.error('Failed to get contract', error);
      return null;
    }
  }, [
    addressOrAddressMap,
    ABI,
    library,
    chainId,
    withSignerIfPossible,
    account,
  ]) as T;
}

export function useNFTPixelCanvasContract(address = NFT_PIXEL_CANVAS_CONTRACT) {
  return useContract<NFTPixelCanvas>(address, NFT_PIXEL_CANVAS_ABI.abi, true);
}
