import { Grid } from '@mui/material';
import React from 'react';
import { useGetOwnedTokens } from 'src/hooks/pixelCanvas';
import { PixelCollection } from '../PixelCollection';

interface PixelCollectionContainerProps {
  account: string | undefined;
  error: string;
}

export const PixelCollectionContainer: React.FunctionComponent<PixelCollectionContainerProps> =
  ({ account, error }) => {
    const { data, isLoading } = useGetOwnedTokens(account);

    return (
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={12} md={10}>
          <PixelCollection
            tokens={data}
            isLoading={isLoading}
            account={account}
            error={error}
          />
        </Grid>
      </Grid>
    );
  };
