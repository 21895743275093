import { Link as MuiLink } from '@mui/material';
import React from 'react';
import isURL from 'validator/es/lib/isURL';

interface TextWithLinksProps {
  tokenText: string;
}

export const TextWithLinks: React.FunctionComponent<TextWithLinksProps> = ({
  tokenText,
}) => {
  const stringParts = tokenText.split(/(\s+)/);

  return (
    <>
      {stringParts.map((url, index) => {
        return isURL(url) ? (
          <React.Fragment key={index}>
            <MuiLink
              href={url}
              target="_blank"
              sx={{
                color: 'white',
                textDecorationColor: 'rgba(255, 255, 255, 0.4)',
              }}
            >
              {url}
            </MuiLink>
            {index < stringParts.length && ' '}
          </React.Fragment>
        ) : (
          <React.Fragment key={index}>
            {url}
            {index < stringParts.length && ' '}
          </React.Fragment>
        );
      })}
    </>
  );
};
