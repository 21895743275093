import { BigNumber } from '@ethersproject/bignumber';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { DATA_ENDPOINT } from 'src/constants/misc';
import { axiosClient } from 'src/utils/axios';

export const useGetImageData = () => {
  const [data, setData] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await fetch(
          'https://data.nftpixelcanvas.com/chain-state-image.json'
        );
        const jsonData = await res.json();

        setData(jsonData.image);
      } catch (e: any) {
        setData([]);
        setError(e.message);
      }
      setLoading(false);
    })();
  }, []);

  return { data, loading, error };
};

export const useGetReservedPixels = (account?: string | null) => {
  return useQuery<unknown, unknown, boolean[]>(
    'reservations',
    async () => {
      try {
        const { data } = await axiosClient.get('/nft-pixel-canvas-reservation');
        return data;
      } catch (error: any) {
        if (error.response) {
          throw error.response.data?.errors;
        }
        if (error.request) {
          throw error.request;
        }
        if (error.message) {
          throw error.message;
        }
        throw error;
      }
    },
    {
      // Refetch the data every 10 seconds if wallet connected
      // otherwise refetch from Infura every 30 seconds
      refetchInterval: !!account ? 10_000 : 30_000,
    }
  );
};

export interface GetReservationData {
  address: string;
  createdAt: string;
  id: number;
  index: number;
  proof: string[];
  root: string;
  type: string;
  valid: boolean;
  minted: boolean;
}

export const useGetResevationsForAddress = (account?: string | null) => {
  return useQuery<unknown, unknown, { data: GetReservationData[] }>(
    'getReservation',
    async () => {
      try {
        const { data } = await axiosClient.get(
          '/nft-pixel-canvas-reservation/reservation',
          {
            params: {
              a: account,
            },
          }
        );
        return data;
      } catch (error: any) {
        if (error.response) {
          throw error.response.data?.errors;
        }
        if (error.request) {
          throw error.request;
        }
        if (error.message) {
          throw error.message;
        }
        throw error;
      }
    },
    {
      enabled: !!account,
    }
  );
};

export interface ChainStateData {
  [key: string]: any;
  data: {
    contractAddress: string;
    time: number;
    timeISO: string;
    image: string[];
    text: string[];
    value: BigNumber[];
  };
}

export const useGetPixelsImageData = () => {
  return useMutation<any>(
    async () => {
      try {
        const { data } = await axios.get(`${DATA_ENDPOINT}/chain-state.json`);
        return data;
      } catch (error: any) {
        if (error.response) {
          throw error.response.data?.errors;
        }
        if (error.request) {
          throw error.request;
        }
        if (error.message) {
          throw error.message;
        }
        throw error;
      }
    },
    {
      mutationKey: 'getPixelsImageData',
    }
  );
};
