import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';

import { network } from '../../connectors';
import { useEagerConnect, useInactiveListener } from '../../hooks/web3';

export const Web3ReactManager = ({ children }: { children: JSX.Element }) => {
  const { active, error, activate } = useWeb3React();

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    if (triedEager && !error && !active) {
      activate(network);
    }
  }, [triedEager, error, activate, active]);

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager);

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error

  return children;
};
